.pokesprite { display: inline-block }
.pokesprite.pokemon { width: 68px; height: 56px; background: url('pokesprite-pokemon-gen8.png'); image-rendering: pixelated; image-rendering: -moz-crisp-edges }
.pokesprite.bulbasaur { background-position: -0px -0px }
.pokesprite.ivysaur { background-position: -70px -0px }
.pokesprite.venusaur { background-position: -140px -0px }
.pokesprite.venusaur-mega { background-position: -210px -0px }
.pokesprite.venusaur-gmax { background-position: -280px -0px }
.pokesprite.charmander { background-position: -350px -0px }
.pokesprite.charmeleon { background-position: -420px -0px }
.pokesprite.charizard { background-position: -490px -0px }
.pokesprite.charizard-gmax { background-position: -560px -0px }
.pokesprite.charizard-mega-x { background-position: -630px -0px }
.pokesprite.charizard-mega-y { background-position: -700px -0px }
.pokesprite.squirtle { background-position: -770px -0px }
.pokesprite.wartortle { background-position: -840px -0px }
.pokesprite.blastoise { background-position: -910px -0px }
.pokesprite.blastoise-mega { background-position: -980px -0px }
.pokesprite.blastoise-gmax { background-position: -1050px -0px }
.pokesprite.caterpie { background-position: -1120px -0px }
.pokesprite.metapod { background-position: -1190px -0px }
.pokesprite.butterfree { background-position: -1260px -0px }
.pokesprite.butterfree-gmax { background-position: -1330px -0px }
.pokesprite.weedle { background-position: -1400px -0px }
.pokesprite.kakuna { background-position: -1470px -0px }
.pokesprite.beedrill { background-position: -1540px -0px }
.pokesprite.beedrill-mega { background-position: -1610px -0px }
.pokesprite.pidgey { background-position: -1680px -0px }
.pokesprite.pidgeotto { background-position: -1750px -0px }
.pokesprite.pidgeot { background-position: -1820px -0px }
.pokesprite.pidgeot-mega { background-position: -1890px -0px }
.pokesprite.rattata { background-position: -1960px -0px }
.pokesprite.rattata-alola { background-position: -2030px -0px }
.pokesprite.raticate { background-position: -2100px -0px }
.pokesprite.raticate-alola { background-position: -0px -58px }
.pokesprite.raticate-totem { background-position: -0px -58px }
.pokesprite.raticate-totem-alola { background-position: -0px -58px }
.pokesprite.spearow { background-position: -70px -58px }
.pokesprite.fearow { background-position: -140px -58px }
.pokesprite.ekans { background-position: -210px -58px }
.pokesprite.arbok { background-position: -280px -58px }
.pokesprite.pikachu { background-position: -350px -58px }
.pokesprite.pikachu.female { background-position: -420px -58px }
.pokesprite.pikachu-alola-cap { background-position: -490px -58px }
.pokesprite.pikachu-alola-cap.female { background-position: -560px -58px }
.pokesprite.pikachu-belle { background-position: -630px -58px }
.pokesprite.pikachu-cosplay { background-position: -700px -58px }
.pokesprite.pikachu-gmax { background-position: -770px -58px }
.pokesprite.pikachu-hoenn-cap { background-position: -840px -58px }
.pokesprite.pikachu-hoenn-cap.female { background-position: -910px -58px }
.pokesprite.pikachu-kalos-cap { background-position: -980px -58px }
.pokesprite.pikachu-kalos-cap.female { background-position: -1050px -58px }
.pokesprite.pikachu-libre { background-position: -1120px -58px }
.pokesprite.pikachu-original-cap { background-position: -1190px -58px }
.pokesprite.pikachu-original-cap.female { background-position: -1260px -58px }
.pokesprite.pikachu-partner-cap { background-position: -1330px -58px }
.pokesprite.pikachu-partner-cap.female { background-position: -1400px -58px }
.pokesprite.pikachu-phd { background-position: -1470px -58px }
.pokesprite.pikachu-pop-star { background-position: -1540px -58px }
.pokesprite.pikachu-rock-star { background-position: -1610px -58px }
.pokesprite.pikachu-sinnoh-cap { background-position: -1680px -58px }
.pokesprite.pikachu-sinnoh-cap.female { background-position: -1750px -58px }
.pokesprite.pikachu-starter { background-position: -1820px -58px }
.pokesprite.pikachu-starter.female { background-position: -1890px -58px }
.pokesprite.pikachu-unova-cap { background-position: -1960px -58px }
.pokesprite.pikachu-unova-cap.female { background-position: -2030px -58px }
.pokesprite.pikachu-world-cap { background-position: -2100px -58px }
.pokesprite.pikachu-world-cap.female { background-position: -0px -116px }
.pokesprite.raichu { background-position: -70px -116px }
.pokesprite.raichu-alola { background-position: -140px -116px }
.pokesprite.sandshrew { background-position: -210px -116px }
.pokesprite.sandshrew-alola { background-position: -280px -116px }
.pokesprite.sandslash { background-position: -350px -116px }
.pokesprite.sandslash-alola { background-position: -420px -116px }
.pokesprite.nidoran-f { background-position: -490px -116px }
.pokesprite.nidorina { background-position: -560px -116px }
.pokesprite.nidoqueen { background-position: -630px -116px }
.pokesprite.nidoran-m { background-position: -700px -116px }
.pokesprite.nidorino { background-position: -770px -116px }
.pokesprite.nidoking { background-position: -840px -116px }
.pokesprite.clefairy { background-position: -910px -116px }
.pokesprite.clefable { background-position: -980px -116px }
.pokesprite.vulpix { background-position: -1050px -116px }
.pokesprite.vulpix-alola { background-position: -1120px -116px }
.pokesprite.ninetales { background-position: -1190px -116px }
.pokesprite.ninetales-alola { background-position: -1260px -116px }
.pokesprite.jigglypuff { background-position: -1330px -116px }
.pokesprite.wigglytuff { background-position: -1400px -116px }
.pokesprite.zubat { background-position: -1470px -116px }
.pokesprite.golbat { background-position: -1540px -116px }
.pokesprite.oddish { background-position: -1610px -116px }
.pokesprite.gloom { background-position: -1680px -116px }
.pokesprite.vileplume { background-position: -1750px -116px }
.pokesprite.paras { background-position: -1820px -116px }
.pokesprite.parasect { background-position: -1890px -116px }
.pokesprite.venonat { background-position: -1960px -116px }
.pokesprite.venomoth { background-position: -2030px -116px }
.pokesprite.diglett { background-position: -2100px -116px }
.pokesprite.diglett-alola { background-position: -0px -174px }
.pokesprite.dugtrio { background-position: -70px -174px }
.pokesprite.dugtrio-alola { background-position: -140px -174px }
.pokesprite.meowth { background-position: -210px -174px }
.pokesprite.meowth-alola { background-position: -280px -174px }
.pokesprite.meowth-galar { background-position: -350px -174px }
.pokesprite.meowth-gmax { background-position: -420px -174px }
.pokesprite.persian { background-position: -490px -174px }
.pokesprite.persian-alola { background-position: -560px -174px }
.pokesprite.psyduck { background-position: -630px -174px }
.pokesprite.golduck { background-position: -700px -174px }
.pokesprite.mankey { background-position: -770px -174px }
.pokesprite.primeape { background-position: -840px -174px }
.pokesprite.growlithe { background-position: -910px -174px }
.pokesprite.arcanine { background-position: -980px -174px }
.pokesprite.poliwag { background-position: -1050px -174px }
.pokesprite.poliwhirl { background-position: -1120px -174px }
.pokesprite.poliwrath { background-position: -1190px -174px }
.pokesprite.abra { background-position: -1260px -174px }
.pokesprite.kadabra { background-position: -1330px -174px }
.pokesprite.alakazam { background-position: -1400px -174px }
.pokesprite.alakazam-mega { background-position: -1470px -174px }
.pokesprite.machop { background-position: -1540px -174px }
.pokesprite.machoke { background-position: -1610px -174px }
.pokesprite.machamp { background-position: -1680px -174px }
.pokesprite.machamp-gmax { background-position: -1750px -174px }
.pokesprite.bellsprout { background-position: -1820px -174px }
.pokesprite.weepinbell { background-position: -1890px -174px }
.pokesprite.victreebel { background-position: -1960px -174px }
.pokesprite.tentacool { background-position: -2030px -174px }
.pokesprite.tentacruel { background-position: -2100px -174px }
.pokesprite.geodude { background-position: -0px -232px }
.pokesprite.geodude-alola { background-position: -70px -232px }
.pokesprite.graveler { background-position: -140px -232px }
.pokesprite.graveler-alola { background-position: -210px -232px }
.pokesprite.golem { background-position: -280px -232px }
.pokesprite.golem-alola { background-position: -350px -232px }
.pokesprite.ponyta { background-position: -420px -232px }
.pokesprite.ponyta-galar { background-position: -490px -232px }
.pokesprite.rapidash { background-position: -560px -232px }
.pokesprite.rapidash-galar { background-position: -630px -232px }
.pokesprite.slowpoke { background-position: -700px -232px }
.pokesprite.slowpoke-galar { background-position: -770px -232px }
.pokesprite.slowbro { background-position: -840px -232px }
.pokesprite.slowbro-mega { background-position: -910px -232px }
.pokesprite.slowbro-galar { background-position: -980px -232px }
.pokesprite.magnemite { background-position: -1050px -232px }
.pokesprite.magneton { background-position: -1120px -232px }
.pokesprite.farfetchd { background-position: -1190px -232px }
.pokesprite.farfetchd-galar { background-position: -1260px -232px }
.pokesprite.doduo { background-position: -1330px -232px }
.pokesprite.dodrio { background-position: -1400px -232px }
.pokesprite.seel { background-position: -1470px -232px }
.pokesprite.dewgong { background-position: -1540px -232px }
.pokesprite.grimer { background-position: -1610px -232px }
.pokesprite.grimer-alola { background-position: -1680px -232px }
.pokesprite.muk { background-position: -1750px -232px }
.pokesprite.muk-alola { background-position: -1820px -232px }
.pokesprite.shellder { background-position: -1890px -232px }
.pokesprite.cloyster { background-position: -1960px -232px }
.pokesprite.gastly { background-position: -2030px -232px }
.pokesprite.haunter { background-position: -2100px -232px }
.pokesprite.gengar { background-position: -0px -290px }
.pokesprite.gengar-gmax { background-position: -70px -290px }
.pokesprite.gengar-mega { background-position: -140px -290px }
.pokesprite.onix { background-position: -210px -290px }
.pokesprite.drowzee { background-position: -280px -290px }
.pokesprite.hypno { background-position: -350px -290px }
.pokesprite.krabby { background-position: -420px -290px }
.pokesprite.kingler { background-position: -490px -290px }
.pokesprite.kingler-gmax { background-position: -560px -290px }
.pokesprite.voltorb { background-position: -630px -290px }
.pokesprite.electrode { background-position: -700px -290px }
.pokesprite.exeggcute { background-position: -770px -290px }
.pokesprite.exeggutor { background-position: -840px -290px }
.pokesprite.exeggutor-alola { background-position: -910px -290px }
.pokesprite.cubone { background-position: -980px -290px }
.pokesprite.marowak { background-position: -1050px -290px }
.pokesprite.marowak-alola { background-position: -1120px -290px }
.pokesprite.marowak-totem { background-position: -1120px -290px }
.pokesprite.marowak-totem-alola { background-position: -1120px -290px }
.pokesprite.hitmonlee { background-position: -1190px -290px }
.pokesprite.hitmonchan { background-position: -1260px -290px }
.pokesprite.lickitung { background-position: -1330px -290px }
.pokesprite.koffing { background-position: -1400px -290px }
.pokesprite.weezing { background-position: -1470px -290px }
.pokesprite.weezing-galar { background-position: -1540px -290px }
.pokesprite.rhyhorn { background-position: -1610px -290px }
.pokesprite.rhydon { background-position: -1680px -290px }
.pokesprite.chansey { background-position: -1750px -290px }
.pokesprite.tangela { background-position: -1820px -290px }
.pokesprite.kangaskhan { background-position: -1890px -290px }
.pokesprite.kangaskhan-mega { background-position: -1960px -290px }
.pokesprite.horsea { background-position: -2030px -290px }
.pokesprite.seadra { background-position: -2100px -290px }
.pokesprite.goldeen { background-position: -0px -348px }
.pokesprite.seaking { background-position: -70px -348px }
.pokesprite.staryu { background-position: -140px -348px }
.pokesprite.starmie { background-position: -210px -348px }
.pokesprite.mr-mime { background-position: -280px -348px }
.pokesprite.mr-mime-galar { background-position: -350px -348px }
.pokesprite.scyther { background-position: -420px -348px }
.pokesprite.jynx { background-position: -490px -348px }
.pokesprite.electabuzz { background-position: -560px -348px }
.pokesprite.magmar { background-position: -630px -348px }
.pokesprite.pinsir { background-position: -700px -348px }
.pokesprite.pinsir-mega { background-position: -770px -348px }
.pokesprite.tauros { background-position: -840px -348px }
.pokesprite.magikarp { background-position: -910px -348px }
.pokesprite.gyarados { background-position: -980px -348px }
.pokesprite.gyarados-mega { background-position: -1050px -348px }
.pokesprite.lapras { background-position: -1120px -348px }
.pokesprite.lapras-gmax { background-position: -1190px -348px }
.pokesprite.ditto { background-position: -1260px -348px }
.pokesprite.eevee { background-position: -1330px -348px }
.pokesprite.eevee-gmax { background-position: -1400px -348px }
.pokesprite.eevee-starter { background-position: -1470px -348px }
.pokesprite.vaporeon { background-position: -1540px -348px }
.pokesprite.jolteon { background-position: -1610px -348px }
.pokesprite.flareon { background-position: -1680px -348px }
.pokesprite.porygon { background-position: -1750px -348px }
.pokesprite.omanyte { background-position: -1820px -348px }
.pokesprite.omastar { background-position: -1890px -348px }
.pokesprite.kabuto { background-position: -1960px -348px }
.pokesprite.kabutops { background-position: -2030px -348px }
.pokesprite.aerodactyl { background-position: -2100px -348px }
.pokesprite.aerodactyl-mega { background-position: -0px -406px }
.pokesprite.snorlax { background-position: -70px -406px }
.pokesprite.snorlax-gmax { background-position: -140px -406px }
.pokesprite.articuno { background-position: -210px -406px }
.pokesprite.zapdos { background-position: -280px -406px }
.pokesprite.moltres { background-position: -350px -406px }
.pokesprite.dratini { background-position: -420px -406px }
.pokesprite.dragonair { background-position: -490px -406px }
.pokesprite.dragonite { background-position: -560px -406px }
.pokesprite.mewtwo { background-position: -630px -406px }
.pokesprite.mewtwo-mega-x { background-position: -700px -406px }
.pokesprite.mewtwo-mega-y { background-position: -770px -406px }
.pokesprite.mew { background-position: -840px -406px }
.pokesprite.chikorita { background-position: -910px -406px }
.pokesprite.bayleef { background-position: -980px -406px }
.pokesprite.meganium { background-position: -1050px -406px }
.pokesprite.cyndaquil { background-position: -1120px -406px }
.pokesprite.quilava { background-position: -1190px -406px }
.pokesprite.typhlosion { background-position: -1260px -406px }
.pokesprite.totodile { background-position: -1330px -406px }
.pokesprite.croconaw { background-position: -1400px -406px }
.pokesprite.feraligatr { background-position: -1470px -406px }
.pokesprite.sentret { background-position: -1540px -406px }
.pokesprite.furret { background-position: -1610px -406px }
.pokesprite.hoothoot { background-position: -1680px -406px }
.pokesprite.noctowl { background-position: -1750px -406px }
.pokesprite.ledyba { background-position: -1820px -406px }
.pokesprite.ledian { background-position: -1890px -406px }
.pokesprite.spinarak { background-position: -1960px -406px }
.pokesprite.ariados { background-position: -2030px -406px }
.pokesprite.crobat { background-position: -2100px -406px }
.pokesprite.chinchou { background-position: -0px -464px }
.pokesprite.lanturn { background-position: -70px -464px }
.pokesprite.pichu { background-position: -140px -464px }
.pokesprite.pichu-spiky-eared { background-position: -210px -464px }
.pokesprite.cleffa { background-position: -280px -464px }
.pokesprite.igglybuff { background-position: -350px -464px }
.pokesprite.togepi { background-position: -420px -464px }
.pokesprite.togetic { background-position: -490px -464px }
.pokesprite.natu { background-position: -560px -464px }
.pokesprite.xatu { background-position: -630px -464px }
.pokesprite.mareep { background-position: -700px -464px }
.pokesprite.flaaffy { background-position: -770px -464px }
.pokesprite.ampharos { background-position: -840px -464px }
.pokesprite.ampharos-mega { background-position: -910px -464px }
.pokesprite.bellossom { background-position: -980px -464px }
.pokesprite.marill { background-position: -1050px -464px }
.pokesprite.azumarill { background-position: -1120px -464px }
.pokesprite.sudowoodo { background-position: -1190px -464px }
.pokesprite.politoed { background-position: -1260px -464px }
.pokesprite.hoppip { background-position: -1330px -464px }
.pokesprite.skiploom { background-position: -1400px -464px }
.pokesprite.jumpluff { background-position: -1470px -464px }
.pokesprite.aipom { background-position: -1540px -464px }
.pokesprite.sunkern { background-position: -1610px -464px }
.pokesprite.sunflora { background-position: -1680px -464px }
.pokesprite.yanma { background-position: -1750px -464px }
.pokesprite.wooper { background-position: -1820px -464px }
.pokesprite.quagsire { background-position: -1890px -464px }
.pokesprite.espeon { background-position: -1960px -464px }
.pokesprite.umbreon { background-position: -2030px -464px }
.pokesprite.murkrow { background-position: -2100px -464px }
.pokesprite.slowking { background-position: -0px -522px }
.pokesprite.misdreavus { background-position: -70px -522px }
.pokesprite.unown { background-position: -140px -522px }
.pokesprite.unown-a { background-position: -140px -522px }
.pokesprite.unown-b { background-position: -210px -522px }
.pokesprite.unown-c { background-position: -280px -522px }
.pokesprite.unown-d { background-position: -350px -522px }
.pokesprite.unown-e { background-position: -420px -522px }
.pokesprite.unown-exclamation { background-position: -490px -522px }
.pokesprite.unown-f { background-position: -560px -522px }
.pokesprite.unown-g { background-position: -630px -522px }
.pokesprite.unown-h { background-position: -700px -522px }
.pokesprite.unown-i { background-position: -770px -522px }
.pokesprite.unown-j { background-position: -840px -522px }
.pokesprite.unown-k { background-position: -910px -522px }
.pokesprite.unown-l { background-position: -980px -522px }
.pokesprite.unown-m { background-position: -1050px -522px }
.pokesprite.unown-n { background-position: -1120px -522px }
.pokesprite.unown-o { background-position: -1190px -522px }
.pokesprite.unown-p { background-position: -1260px -522px }
.pokesprite.unown-q { background-position: -1330px -522px }
.pokesprite.unown-question { background-position: -1400px -522px }
.pokesprite.unown-r { background-position: -1470px -522px }
.pokesprite.unown-s { background-position: -1540px -522px }
.pokesprite.unown-t { background-position: -1610px -522px }
.pokesprite.unown-u { background-position: -1680px -522px }
.pokesprite.unown-v { background-position: -1750px -522px }
.pokesprite.unown-w { background-position: -1820px -522px }
.pokesprite.unown-x { background-position: -1890px -522px }
.pokesprite.unown-y { background-position: -1960px -522px }
.pokesprite.unown-z { background-position: -2030px -522px }
.pokesprite.wobbuffet { background-position: -2100px -522px }
.pokesprite.girafarig { background-position: -0px -580px }
.pokesprite.pineco { background-position: -70px -580px }
.pokesprite.forretress { background-position: -140px -580px }
.pokesprite.dunsparce { background-position: -210px -580px }
.pokesprite.gligar { background-position: -280px -580px }
.pokesprite.steelix { background-position: -350px -580px }
.pokesprite.steelix-mega { background-position: -420px -580px }
.pokesprite.snubbull { background-position: -490px -580px }
.pokesprite.granbull { background-position: -560px -580px }
.pokesprite.qwilfish { background-position: -630px -580px }
.pokesprite.scizor { background-position: -700px -580px }
.pokesprite.scizor-mega { background-position: -770px -580px }
.pokesprite.shuckle { background-position: -840px -580px }
.pokesprite.heracross { background-position: -910px -580px }
.pokesprite.heracross-mega { background-position: -980px -580px }
.pokesprite.sneasel { background-position: -1050px -580px }
.pokesprite.teddiursa { background-position: -1120px -580px }
.pokesprite.ursaring { background-position: -1190px -580px }
.pokesprite.slugma { background-position: -1260px -580px }
.pokesprite.magcargo { background-position: -1330px -580px }
.pokesprite.swinub { background-position: -1400px -580px }
.pokesprite.piloswine { background-position: -1470px -580px }
.pokesprite.corsola { background-position: -1540px -580px }
.pokesprite.corsola-galar { background-position: -1610px -580px }
.pokesprite.remoraid { background-position: -1680px -580px }
.pokesprite.octillery { background-position: -1750px -580px }
.pokesprite.delibird { background-position: -1820px -580px }
.pokesprite.mantine { background-position: -1890px -580px }
.pokesprite.skarmory { background-position: -1960px -580px }
.pokesprite.houndour { background-position: -2030px -580px }
.pokesprite.houndoom { background-position: -2100px -580px }
.pokesprite.houndoom-mega { background-position: -0px -638px }
.pokesprite.kingdra { background-position: -70px -638px }
.pokesprite.phanpy { background-position: -140px -638px }
.pokesprite.donphan { background-position: -210px -638px }
.pokesprite.porygon2 { background-position: -280px -638px }
.pokesprite.stantler { background-position: -350px -638px }
.pokesprite.smeargle { background-position: -420px -638px }
.pokesprite.tyrogue { background-position: -490px -638px }
.pokesprite.hitmontop { background-position: -560px -638px }
.pokesprite.smoochum { background-position: -630px -638px }
.pokesprite.elekid { background-position: -700px -638px }
.pokesprite.magby { background-position: -770px -638px }
.pokesprite.miltank { background-position: -840px -638px }
.pokesprite.blissey { background-position: -910px -638px }
.pokesprite.raikou { background-position: -980px -638px }
.pokesprite.entei { background-position: -1050px -638px }
.pokesprite.suicune { background-position: -1120px -638px }
.pokesprite.larvitar { background-position: -1190px -638px }
.pokesprite.pupitar { background-position: -1260px -638px }
.pokesprite.tyranitar { background-position: -1330px -638px }
.pokesprite.tyranitar-mega { background-position: -1400px -638px }
.pokesprite.lugia { background-position: -1470px -638px }
.pokesprite.ho-oh { background-position: -1540px -638px }
.pokesprite.celebi { background-position: -1610px -638px }
.pokesprite.treecko { background-position: -1680px -638px }
.pokesprite.grovyle { background-position: -1750px -638px }
.pokesprite.sceptile { background-position: -1820px -638px }
.pokesprite.sceptile-mega { background-position: -1890px -638px }
.pokesprite.torchic { background-position: -1960px -638px }
.pokesprite.combusken { background-position: -2030px -638px }
.pokesprite.blaziken { background-position: -2100px -638px }
.pokesprite.blaziken-mega { background-position: -0px -696px }
.pokesprite.mudkip { background-position: -70px -696px }
.pokesprite.marshtomp { background-position: -140px -696px }
.pokesprite.swampert { background-position: -210px -696px }
.pokesprite.swampert-mega { background-position: -280px -696px }
.pokesprite.poochyena { background-position: -350px -696px }
.pokesprite.mightyena { background-position: -420px -696px }
.pokesprite.zigzagoon { background-position: -490px -696px }
.pokesprite.zigzagoon-galar { background-position: -560px -696px }
.pokesprite.linoone { background-position: -630px -696px }
.pokesprite.linoone-galar { background-position: -700px -696px }
.pokesprite.wurmple { background-position: -770px -696px }
.pokesprite.silcoon { background-position: -840px -696px }
.pokesprite.beautifly { background-position: -910px -696px }
.pokesprite.cascoon { background-position: -980px -696px }
.pokesprite.dustox { background-position: -1050px -696px }
.pokesprite.lotad { background-position: -1120px -696px }
.pokesprite.lombre { background-position: -1190px -696px }
.pokesprite.ludicolo { background-position: -1260px -696px }
.pokesprite.seedot { background-position: -1330px -696px }
.pokesprite.nuzleaf { background-position: -1400px -696px }
.pokesprite.shiftry { background-position: -1470px -696px }
.pokesprite.taillow { background-position: -1540px -696px }
.pokesprite.swellow { background-position: -1610px -696px }
.pokesprite.wingull { background-position: -1680px -696px }
.pokesprite.pelipper { background-position: -1750px -696px }
.pokesprite.ralts { background-position: -1820px -696px }
.pokesprite.kirlia { background-position: -1890px -696px }
.pokesprite.gardevoir { background-position: -1960px -696px }
.pokesprite.gardevoir-mega { background-position: -2030px -696px }
.pokesprite.surskit { background-position: -2100px -696px }
.pokesprite.masquerain { background-position: -0px -754px }
.pokesprite.shroomish { background-position: -70px -754px }
.pokesprite.breloom { background-position: -140px -754px }
.pokesprite.slakoth { background-position: -210px -754px }
.pokesprite.vigoroth { background-position: -280px -754px }
.pokesprite.slaking { background-position: -350px -754px }
.pokesprite.nincada { background-position: -420px -754px }
.pokesprite.ninjask { background-position: -490px -754px }
.pokesprite.shedinja { background-position: -560px -754px }
.pokesprite.whismur { background-position: -630px -754px }
.pokesprite.loudred { background-position: -700px -754px }
.pokesprite.exploud { background-position: -770px -754px }
.pokesprite.makuhita { background-position: -840px -754px }
.pokesprite.hariyama { background-position: -910px -754px }
.pokesprite.azurill { background-position: -980px -754px }
.pokesprite.nosepass { background-position: -1050px -754px }
.pokesprite.skitty { background-position: -1120px -754px }
.pokesprite.delcatty { background-position: -1190px -754px }
.pokesprite.sableye { background-position: -1260px -754px }
.pokesprite.sableye-mega { background-position: -1330px -754px }
.pokesprite.mawile { background-position: -1400px -754px }
.pokesprite.mawile-mega { background-position: -1470px -754px }
.pokesprite.aron { background-position: -1540px -754px }
.pokesprite.lairon { background-position: -1610px -754px }
.pokesprite.aggron { background-position: -1680px -754px }
.pokesprite.aggron-mega { background-position: -1750px -754px }
.pokesprite.meditite { background-position: -1820px -754px }
.pokesprite.medicham { background-position: -1890px -754px }
.pokesprite.medicham-mega { background-position: -1960px -754px }
.pokesprite.electrike { background-position: -2030px -754px }
.pokesprite.manectric { background-position: -2100px -754px }
.pokesprite.manectric-mega { background-position: -0px -812px }
.pokesprite.plusle { background-position: -70px -812px }
.pokesprite.minun { background-position: -140px -812px }
.pokesprite.volbeat { background-position: -210px -812px }
.pokesprite.illumise { background-position: -280px -812px }
.pokesprite.roselia { background-position: -350px -812px }
.pokesprite.gulpin { background-position: -420px -812px }
.pokesprite.swalot { background-position: -490px -812px }
.pokesprite.carvanha { background-position: -560px -812px }
.pokesprite.sharpedo { background-position: -630px -812px }
.pokesprite.sharpedo-mega { background-position: -700px -812px }
.pokesprite.wailmer { background-position: -770px -812px }
.pokesprite.wailord { background-position: -840px -812px }
.pokesprite.numel { background-position: -910px -812px }
.pokesprite.camerupt { background-position: -980px -812px }
.pokesprite.camerupt-mega { background-position: -1050px -812px }
.pokesprite.torkoal { background-position: -1120px -812px }
.pokesprite.spoink { background-position: -1190px -812px }
.pokesprite.grumpig { background-position: -1260px -812px }
.pokesprite.spinda { background-position: -1330px -812px }
.pokesprite.spinda-blank { background-position: -1400px -812px }
.pokesprite.spinda-filled { background-position: -1470px -812px }
.pokesprite.trapinch { background-position: -1540px -812px }
.pokesprite.vibrava { background-position: -1610px -812px }
.pokesprite.flygon { background-position: -1680px -812px }
.pokesprite.cacnea { background-position: -1750px -812px }
.pokesprite.cacturne { background-position: -1820px -812px }
.pokesprite.swablu { background-position: -1890px -812px }
.pokesprite.altaria { background-position: -1960px -812px }
.pokesprite.altaria-mega { background-position: -2030px -812px }
.pokesprite.zangoose { background-position: -2100px -812px }
.pokesprite.seviper { background-position: -0px -870px }
.pokesprite.lunatone { background-position: -70px -870px }
.pokesprite.solrock { background-position: -140px -870px }
.pokesprite.barboach { background-position: -210px -870px }
.pokesprite.whiscash { background-position: -280px -870px }
.pokesprite.corphish { background-position: -350px -870px }
.pokesprite.crawdaunt { background-position: -420px -870px }
.pokesprite.baltoy { background-position: -490px -870px }
.pokesprite.claydol { background-position: -560px -870px }
.pokesprite.lileep { background-position: -630px -870px }
.pokesprite.cradily { background-position: -700px -870px }
.pokesprite.anorith { background-position: -770px -870px }
.pokesprite.armaldo { background-position: -840px -870px }
.pokesprite.feebas { background-position: -910px -870px }
.pokesprite.milotic { background-position: -980px -870px }
.pokesprite.castform { background-position: -1050px -870px }
.pokesprite.castform-rainy { background-position: -1120px -870px }
.pokesprite.castform-snowy { background-position: -1190px -870px }
.pokesprite.castform-sunny { background-position: -1260px -870px }
.pokesprite.kecleon { background-position: -1330px -870px }
.pokesprite.shuppet { background-position: -1400px -870px }
.pokesprite.banette { background-position: -1470px -870px }
.pokesprite.banette-mega { background-position: -1540px -870px }
.pokesprite.duskull { background-position: -1610px -870px }
.pokesprite.dusclops { background-position: -1680px -870px }
.pokesprite.tropius { background-position: -1750px -870px }
.pokesprite.chimecho { background-position: -1820px -870px }
.pokesprite.absol { background-position: -1890px -870px }
.pokesprite.absol-mega { background-position: -1960px -870px }
.pokesprite.wynaut { background-position: -2030px -870px }
.pokesprite.snorunt { background-position: -2100px -870px }
.pokesprite.glalie { background-position: -0px -928px }
.pokesprite.glalie-mega { background-position: -70px -928px }
.pokesprite.spheal { background-position: -140px -928px }
.pokesprite.sealeo { background-position: -210px -928px }
.pokesprite.walrein { background-position: -280px -928px }
.pokesprite.clamperl { background-position: -350px -928px }
.pokesprite.huntail { background-position: -420px -928px }
.pokesprite.gorebyss { background-position: -490px -928px }
.pokesprite.relicanth { background-position: -560px -928px }
.pokesprite.luvdisc { background-position: -630px -928px }
.pokesprite.bagon { background-position: -700px -928px }
.pokesprite.shelgon { background-position: -770px -928px }
.pokesprite.salamence { background-position: -840px -928px }
.pokesprite.salamence-mega { background-position: -910px -928px }
.pokesprite.beldum { background-position: -980px -928px }
.pokesprite.metang { background-position: -1050px -928px }
.pokesprite.metagross { background-position: -1120px -928px }
.pokesprite.metagross-mega { background-position: -1190px -928px }
.pokesprite.regirock { background-position: -1260px -928px }
.pokesprite.regice { background-position: -1330px -928px }
.pokesprite.registeel { background-position: -1400px -928px }
.pokesprite.latias { background-position: -1470px -928px }
.pokesprite.latias-mega { background-position: -1540px -928px }
.pokesprite.latios { background-position: -1610px -928px }
.pokesprite.latios-mega { background-position: -1680px -928px }
.pokesprite.kyogre { background-position: -1750px -928px }
.pokesprite.kyogre-primal { background-position: -1820px -928px }
.pokesprite.groudon { background-position: -1890px -928px }
.pokesprite.groudon-primal { background-position: -1960px -928px }
.pokesprite.rayquaza { background-position: -2030px -928px }
.pokesprite.rayquaza-mega { background-position: -2100px -928px }
.pokesprite.jirachi { background-position: -0px -986px }
.pokesprite.deoxys { background-position: -70px -986px }
.pokesprite.deoxys-normal { background-position: -70px -986px }
.pokesprite.deoxys-attack { background-position: -140px -986px }
.pokesprite.deoxys-defense { background-position: -210px -986px }
.pokesprite.deoxys-speed { background-position: -280px -986px }
.pokesprite.turtwig { background-position: -350px -986px }
.pokesprite.grotle { background-position: -420px -986px }
.pokesprite.torterra { background-position: -490px -986px }
.pokesprite.chimchar { background-position: -560px -986px }
.pokesprite.monferno { background-position: -630px -986px }
.pokesprite.infernape { background-position: -700px -986px }
.pokesprite.piplup { background-position: -770px -986px }
.pokesprite.prinplup { background-position: -840px -986px }
.pokesprite.empoleon { background-position: -910px -986px }
.pokesprite.starly { background-position: -980px -986px }
.pokesprite.staravia { background-position: -1050px -986px }
.pokesprite.staraptor { background-position: -1120px -986px }
.pokesprite.bidoof { background-position: -1190px -986px }
.pokesprite.bibarel { background-position: -1260px -986px }
.pokesprite.kricketot { background-position: -1330px -986px }
.pokesprite.kricketune { background-position: -1400px -986px }
.pokesprite.shinx { background-position: -1470px -986px }
.pokesprite.luxio { background-position: -1540px -986px }
.pokesprite.luxray { background-position: -1610px -986px }
.pokesprite.budew { background-position: -1680px -986px }
.pokesprite.roserade { background-position: -1750px -986px }
.pokesprite.cranidos { background-position: -1820px -986px }
.pokesprite.rampardos { background-position: -1890px -986px }
.pokesprite.shieldon { background-position: -1960px -986px }
.pokesprite.bastiodon { background-position: -2030px -986px }
.pokesprite.burmy { background-position: -2100px -986px }
.pokesprite.burmy-plant { background-position: -2100px -986px }
.pokesprite.burmy-sandy { background-position: -0px -1044px }
.pokesprite.burmy-trash { background-position: -70px -1044px }
.pokesprite.wormadam { background-position: -140px -1044px }
.pokesprite.wormadam-plant { background-position: -140px -1044px }
.pokesprite.wormadam-sandy { background-position: -210px -1044px }
.pokesprite.wormadam-trash { background-position: -280px -1044px }
.pokesprite.mothim { background-position: -350px -1044px }
.pokesprite.mothim-plant { background-position: -350px -1044px }
.pokesprite.mothim-sandy { background-position: -350px -1044px }
.pokesprite.mothim-trash { background-position: -350px -1044px }
.pokesprite.combee { background-position: -420px -1044px }
.pokesprite.vespiquen { background-position: -490px -1044px }
.pokesprite.pachirisu { background-position: -560px -1044px }
.pokesprite.buizel { background-position: -630px -1044px }
.pokesprite.floatzel { background-position: -700px -1044px }
.pokesprite.cherubi { background-position: -770px -1044px }
.pokesprite.cherrim { background-position: -840px -1044px }
.pokesprite.cherrim-overcast { background-position: -840px -1044px }
.pokesprite.cherrim-sunshine { background-position: -910px -1044px }
.pokesprite.shellos { background-position: -980px -1044px }
.pokesprite.shellos-west { background-position: -980px -1044px }
.pokesprite.shellos-east { background-position: -1050px -1044px }
.pokesprite.gastrodon { background-position: -1120px -1044px }
.pokesprite.gastrodon-west { background-position: -1120px -1044px }
.pokesprite.gastrodon-east { background-position: -1190px -1044px }
.pokesprite.ambipom { background-position: -1260px -1044px }
.pokesprite.drifloon { background-position: -1330px -1044px }
.pokesprite.drifblim { background-position: -1400px -1044px }
.pokesprite.buneary { background-position: -1470px -1044px }
.pokesprite.lopunny { background-position: -1540px -1044px }
.pokesprite.lopunny-mega { background-position: -1610px -1044px }
.pokesprite.mismagius { background-position: -1680px -1044px }
.pokesprite.honchkrow { background-position: -1750px -1044px }
.pokesprite.glameow { background-position: -1820px -1044px }
.pokesprite.purugly { background-position: -1890px -1044px }
.pokesprite.chingling { background-position: -1960px -1044px }
.pokesprite.stunky { background-position: -2030px -1044px }
.pokesprite.skuntank { background-position: -2100px -1044px }
.pokesprite.bronzor { background-position: -0px -1102px }
.pokesprite.bronzong { background-position: -70px -1102px }
.pokesprite.bonsly { background-position: -140px -1102px }
.pokesprite.mime-jr { background-position: -210px -1102px }
.pokesprite.happiny { background-position: -280px -1102px }
.pokesprite.chatot { background-position: -350px -1102px }
.pokesprite.spiritomb { background-position: -420px -1102px }
.pokesprite.gible { background-position: -490px -1102px }
.pokesprite.gabite { background-position: -560px -1102px }
.pokesprite.garchomp { background-position: -630px -1102px }
.pokesprite.garchomp-mega { background-position: -700px -1102px }
.pokesprite.munchlax { background-position: -770px -1102px }
.pokesprite.riolu { background-position: -840px -1102px }
.pokesprite.lucario { background-position: -910px -1102px }
.pokesprite.lucario-mega { background-position: -980px -1102px }
.pokesprite.hippopotas { background-position: -1050px -1102px }
.pokesprite.hippopotas.female { background-position: -1120px -1102px }
.pokesprite.hippowdon { background-position: -1190px -1102px }
.pokesprite.hippowdon.female { background-position: -1260px -1102px }
.pokesprite.skorupi { background-position: -1330px -1102px }
.pokesprite.drapion { background-position: -1400px -1102px }
.pokesprite.croagunk { background-position: -1470px -1102px }
.pokesprite.toxicroak { background-position: -1540px -1102px }
.pokesprite.carnivine { background-position: -1610px -1102px }
.pokesprite.finneon { background-position: -1680px -1102px }
.pokesprite.lumineon { background-position: -1750px -1102px }
.pokesprite.mantyke { background-position: -1820px -1102px }
.pokesprite.snover { background-position: -1890px -1102px }
.pokesprite.abomasnow { background-position: -1960px -1102px }
.pokesprite.abomasnow-mega { background-position: -2030px -1102px }
.pokesprite.weavile { background-position: -2100px -1102px }
.pokesprite.magnezone { background-position: -0px -1160px }
.pokesprite.lickilicky { background-position: -70px -1160px }
.pokesprite.rhyperior { background-position: -140px -1160px }
.pokesprite.tangrowth { background-position: -210px -1160px }
.pokesprite.electivire { background-position: -280px -1160px }
.pokesprite.magmortar { background-position: -350px -1160px }
.pokesprite.togekiss { background-position: -420px -1160px }
.pokesprite.yanmega { background-position: -490px -1160px }
.pokesprite.leafeon { background-position: -560px -1160px }
.pokesprite.glaceon { background-position: -630px -1160px }
.pokesprite.gliscor { background-position: -700px -1160px }
.pokesprite.mamoswine { background-position: -770px -1160px }
.pokesprite.porygon-z { background-position: -840px -1160px }
.pokesprite.gallade { background-position: -910px -1160px }
.pokesprite.gallade-mega { background-position: -980px -1160px }
.pokesprite.probopass { background-position: -1050px -1160px }
.pokesprite.dusknoir { background-position: -1120px -1160px }
.pokesprite.froslass { background-position: -1190px -1160px }
.pokesprite.rotom { background-position: -1260px -1160px }
.pokesprite.rotom-fan { background-position: -1330px -1160px }
.pokesprite.rotom-frost { background-position: -1400px -1160px }
.pokesprite.rotom-heat { background-position: -1470px -1160px }
.pokesprite.rotom-mow { background-position: -1540px -1160px }
.pokesprite.rotom-wash { background-position: -1610px -1160px }
.pokesprite.uxie { background-position: -1680px -1160px }
.pokesprite.mesprit { background-position: -1750px -1160px }
.pokesprite.azelf { background-position: -1820px -1160px }
.pokesprite.dialga { background-position: -1890px -1160px }
.pokesprite.palkia { background-position: -1960px -1160px }
.pokesprite.heatran { background-position: -2030px -1160px }
.pokesprite.regigigas { background-position: -2100px -1160px }
.pokesprite.giratina { background-position: -0px -1218px }
.pokesprite.giratina-altered { background-position: -0px -1218px }
.pokesprite.giratina-origin { background-position: -70px -1218px }
.pokesprite.cresselia { background-position: -140px -1218px }
.pokesprite.phione { background-position: -210px -1218px }
.pokesprite.manaphy { background-position: -280px -1218px }
.pokesprite.darkrai { background-position: -350px -1218px }
.pokesprite.shaymin { background-position: -420px -1218px }
.pokesprite.shaymin-land { background-position: -420px -1218px }
.pokesprite.shaymin-sky { background-position: -490px -1218px }
.pokesprite.arceus { background-position: -560px -1218px }
.pokesprite.arceus-normal { background-position: -560px -1218px }
.pokesprite.arceus-unknown { background-position: -560px -1218px }
.pokesprite.arceus-bug { background-position: -630px -1218px }
.pokesprite.arceus-dark { background-position: -700px -1218px }
.pokesprite.arceus-dragon { background-position: -770px -1218px }
.pokesprite.arceus-electric { background-position: -840px -1218px }
.pokesprite.arceus-fairy { background-position: -910px -1218px }
.pokesprite.arceus-fighting { background-position: -980px -1218px }
.pokesprite.arceus-fire { background-position: -1050px -1218px }
.pokesprite.arceus-flying { background-position: -1120px -1218px }
.pokesprite.arceus-ghost { background-position: -1190px -1218px }
.pokesprite.arceus-grass { background-position: -1260px -1218px }
.pokesprite.arceus-ground { background-position: -1330px -1218px }
.pokesprite.arceus-ice { background-position: -1400px -1218px }
.pokesprite.arceus-poison { background-position: -1470px -1218px }
.pokesprite.arceus-psychic { background-position: -1540px -1218px }
.pokesprite.arceus-rock { background-position: -1610px -1218px }
.pokesprite.arceus-steel { background-position: -1680px -1218px }
.pokesprite.arceus-water { background-position: -1750px -1218px }
.pokesprite.victini { background-position: -1820px -1218px }
.pokesprite.snivy { background-position: -1890px -1218px }
.pokesprite.servine { background-position: -1960px -1218px }
.pokesprite.serperior { background-position: -2030px -1218px }
.pokesprite.tepig { background-position: -2100px -1218px }
.pokesprite.pignite { background-position: -0px -1276px }
.pokesprite.emboar { background-position: -70px -1276px }
.pokesprite.oshawott { background-position: -140px -1276px }
.pokesprite.dewott { background-position: -210px -1276px }
.pokesprite.samurott { background-position: -280px -1276px }
.pokesprite.patrat { background-position: -350px -1276px }
.pokesprite.watchog { background-position: -420px -1276px }
.pokesprite.lillipup { background-position: -490px -1276px }
.pokesprite.herdier { background-position: -560px -1276px }
.pokesprite.stoutland { background-position: -630px -1276px }
.pokesprite.purrloin { background-position: -700px -1276px }
.pokesprite.liepard { background-position: -770px -1276px }
.pokesprite.pansage { background-position: -840px -1276px }
.pokesprite.simisage { background-position: -910px -1276px }
.pokesprite.pansear { background-position: -980px -1276px }
.pokesprite.simisear { background-position: -1050px -1276px }
.pokesprite.panpour { background-position: -1120px -1276px }
.pokesprite.simipour { background-position: -1190px -1276px }
.pokesprite.munna { background-position: -1260px -1276px }
.pokesprite.musharna { background-position: -1330px -1276px }
.pokesprite.pidove { background-position: -1400px -1276px }
.pokesprite.tranquill { background-position: -1470px -1276px }
.pokesprite.unfezant { background-position: -1540px -1276px }
.pokesprite.unfezant.female { background-position: -1610px -1276px }
.pokesprite.blitzle { background-position: -1680px -1276px }
.pokesprite.zebstrika { background-position: -1750px -1276px }
.pokesprite.roggenrola { background-position: -1820px -1276px }
.pokesprite.boldore { background-position: -1890px -1276px }
.pokesprite.gigalith { background-position: -1960px -1276px }
.pokesprite.woobat { background-position: -2030px -1276px }
.pokesprite.swoobat { background-position: -2100px -1276px }
.pokesprite.drilbur { background-position: -0px -1334px }
.pokesprite.excadrill { background-position: -70px -1334px }
.pokesprite.audino { background-position: -140px -1334px }
.pokesprite.audino-mega { background-position: -210px -1334px }
.pokesprite.timburr { background-position: -280px -1334px }
.pokesprite.gurdurr { background-position: -350px -1334px }
.pokesprite.conkeldurr { background-position: -420px -1334px }
.pokesprite.tympole { background-position: -490px -1334px }
.pokesprite.palpitoad { background-position: -560px -1334px }
.pokesprite.seismitoad { background-position: -630px -1334px }
.pokesprite.throh { background-position: -700px -1334px }
.pokesprite.sawk { background-position: -770px -1334px }
.pokesprite.sewaddle { background-position: -840px -1334px }
.pokesprite.swadloon { background-position: -910px -1334px }
.pokesprite.leavanny { background-position: -980px -1334px }
.pokesprite.venipede { background-position: -1050px -1334px }
.pokesprite.whirlipede { background-position: -1120px -1334px }
.pokesprite.scolipede { background-position: -1190px -1334px }
.pokesprite.cottonee { background-position: -1260px -1334px }
.pokesprite.whimsicott { background-position: -1330px -1334px }
.pokesprite.petilil { background-position: -1400px -1334px }
.pokesprite.lilligant { background-position: -1470px -1334px }
.pokesprite.basculin { background-position: -1540px -1334px }
.pokesprite.basculin-red-striped { background-position: -1540px -1334px }
.pokesprite.basculin-blue-striped { background-position: -1610px -1334px }
.pokesprite.sandile { background-position: -1680px -1334px }
.pokesprite.krokorok { background-position: -1750px -1334px }
.pokesprite.krookodile { background-position: -1820px -1334px }
.pokesprite.darumaka { background-position: -1890px -1334px }
.pokesprite.darumaka-galar { background-position: -1960px -1334px }
.pokesprite.darmanitan { background-position: -2030px -1334px }
.pokesprite.darmanitan-standard { background-position: -2030px -1334px }
.pokesprite.darmanitan-galar { background-position: -2100px -1334px }
.pokesprite.darmanitan-galar-zen { background-position: -0px -1392px }
.pokesprite.darmanitan-zen { background-position: -70px -1392px }
.pokesprite.maractus { background-position: -140px -1392px }
.pokesprite.dwebble { background-position: -210px -1392px }
.pokesprite.crustle { background-position: -280px -1392px }
.pokesprite.scraggy { background-position: -350px -1392px }
.pokesprite.scrafty { background-position: -420px -1392px }
.pokesprite.sigilyph { background-position: -490px -1392px }
.pokesprite.yamask { background-position: -560px -1392px }
.pokesprite.yamask-galar { background-position: -630px -1392px }
.pokesprite.cofagrigus { background-position: -700px -1392px }
.pokesprite.tirtouga { background-position: -770px -1392px }
.pokesprite.carracosta { background-position: -840px -1392px }
.pokesprite.archen { background-position: -910px -1392px }
.pokesprite.archeops { background-position: -980px -1392px }
.pokesprite.trubbish { background-position: -1050px -1392px }
.pokesprite.garbodor { background-position: -1120px -1392px }
.pokesprite.garbodor-gmax { background-position: -1190px -1392px }
.pokesprite.zorua { background-position: -1260px -1392px }
.pokesprite.zoroark { background-position: -1330px -1392px }
.pokesprite.minccino { background-position: -1400px -1392px }
.pokesprite.cinccino { background-position: -1470px -1392px }
.pokesprite.gothita { background-position: -1540px -1392px }
.pokesprite.gothorita { background-position: -1610px -1392px }
.pokesprite.gothitelle { background-position: -1680px -1392px }
.pokesprite.solosis { background-position: -1750px -1392px }
.pokesprite.duosion { background-position: -1820px -1392px }
.pokesprite.reuniclus { background-position: -1890px -1392px }
.pokesprite.ducklett { background-position: -1960px -1392px }
.pokesprite.swanna { background-position: -2030px -1392px }
.pokesprite.vanillite { background-position: -2100px -1392px }
.pokesprite.vanillish { background-position: -0px -1450px }
.pokesprite.vanilluxe { background-position: -70px -1450px }
.pokesprite.deerling { background-position: -140px -1450px }
.pokesprite.deerling-spring { background-position: -140px -1450px }
.pokesprite.deerling-autumn { background-position: -210px -1450px }
.pokesprite.deerling-summer { background-position: -280px -1450px }
.pokesprite.deerling-winter { background-position: -350px -1450px }
.pokesprite.sawsbuck { background-position: -420px -1450px }
.pokesprite.sawsbuck-spring { background-position: -420px -1450px }
.pokesprite.sawsbuck-autumn { background-position: -490px -1450px }
.pokesprite.sawsbuck-summer { background-position: -560px -1450px }
.pokesprite.sawsbuck-winter { background-position: -630px -1450px }
.pokesprite.emolga { background-position: -700px -1450px }
.pokesprite.karrablast { background-position: -770px -1450px }
.pokesprite.escavalier { background-position: -840px -1450px }
.pokesprite.foongus { background-position: -910px -1450px }
.pokesprite.amoonguss { background-position: -980px -1450px }
.pokesprite.frillish { background-position: -1050px -1450px }
.pokesprite.frillish.female { background-position: -1120px -1450px }
.pokesprite.jellicent { background-position: -1190px -1450px }
.pokesprite.jellicent.female { background-position: -1260px -1450px }
.pokesprite.alomomola { background-position: -1330px -1450px }
.pokesprite.joltik { background-position: -1400px -1450px }
.pokesprite.galvantula { background-position: -1470px -1450px }
.pokesprite.ferroseed { background-position: -1540px -1450px }
.pokesprite.ferrothorn { background-position: -1610px -1450px }
.pokesprite.klink { background-position: -1680px -1450px }
.pokesprite.klang { background-position: -1750px -1450px }
.pokesprite.klinklang { background-position: -1820px -1450px }
.pokesprite.tynamo { background-position: -1890px -1450px }
.pokesprite.eelektrik { background-position: -1960px -1450px }
.pokesprite.eelektross { background-position: -2030px -1450px }
.pokesprite.elgyem { background-position: -2100px -1450px }
.pokesprite.beheeyem { background-position: -0px -1508px }
.pokesprite.litwick { background-position: -70px -1508px }
.pokesprite.lampent { background-position: -140px -1508px }
.pokesprite.chandelure { background-position: -210px -1508px }
.pokesprite.axew { background-position: -280px -1508px }
.pokesprite.fraxure { background-position: -350px -1508px }
.pokesprite.haxorus { background-position: -420px -1508px }
.pokesprite.cubchoo { background-position: -490px -1508px }
.pokesprite.beartic { background-position: -560px -1508px }
.pokesprite.cryogonal { background-position: -630px -1508px }
.pokesprite.shelmet { background-position: -700px -1508px }
.pokesprite.accelgor { background-position: -770px -1508px }
.pokesprite.stunfisk { background-position: -840px -1508px }
.pokesprite.stunfisk-galar { background-position: -910px -1508px }
.pokesprite.mienfoo { background-position: -980px -1508px }
.pokesprite.mienshao { background-position: -1050px -1508px }
.pokesprite.druddigon { background-position: -1120px -1508px }
.pokesprite.golett { background-position: -1190px -1508px }
.pokesprite.golurk { background-position: -1260px -1508px }
.pokesprite.pawniard { background-position: -1330px -1508px }
.pokesprite.bisharp { background-position: -1400px -1508px }
.pokesprite.bouffalant { background-position: -1470px -1508px }
.pokesprite.rufflet { background-position: -1540px -1508px }
.pokesprite.braviary { background-position: -1610px -1508px }
.pokesprite.vullaby { background-position: -1680px -1508px }
.pokesprite.mandibuzz { background-position: -1750px -1508px }
.pokesprite.heatmor { background-position: -1820px -1508px }
.pokesprite.durant { background-position: -1890px -1508px }
.pokesprite.deino { background-position: -1960px -1508px }
.pokesprite.zweilous { background-position: -2030px -1508px }
.pokesprite.hydreigon { background-position: -2100px -1508px }
.pokesprite.larvesta { background-position: -0px -1566px }
.pokesprite.volcarona { background-position: -70px -1566px }
.pokesprite.cobalion { background-position: -140px -1566px }
.pokesprite.terrakion { background-position: -210px -1566px }
.pokesprite.virizion { background-position: -280px -1566px }
.pokesprite.tornadus { background-position: -350px -1566px }
.pokesprite.tornadus-incarnate { background-position: -350px -1566px }
.pokesprite.tornadus-therian { background-position: -420px -1566px }
.pokesprite.thundurus { background-position: -490px -1566px }
.pokesprite.thundurus-incarnate { background-position: -490px -1566px }
.pokesprite.thundurus-therian { background-position: -560px -1566px }
.pokesprite.reshiram { background-position: -630px -1566px }
.pokesprite.zekrom { background-position: -700px -1566px }
.pokesprite.landorus { background-position: -770px -1566px }
.pokesprite.landorus-incarnate { background-position: -770px -1566px }
.pokesprite.landorus-therian { background-position: -840px -1566px }
.pokesprite.kyurem { background-position: -910px -1566px }
.pokesprite.kyurem-black { background-position: -980px -1566px }
.pokesprite.kyurem-white { background-position: -1050px -1566px }
.pokesprite.keldeo { background-position: -1120px -1566px }
.pokesprite.keldeo-ordinary { background-position: -1120px -1566px }
.pokesprite.keldeo-resolute { background-position: -1190px -1566px }
.pokesprite.meloetta { background-position: -1260px -1566px }
.pokesprite.meloetta-aria { background-position: -1260px -1566px }
.pokesprite.meloetta-pirouette { background-position: -1330px -1566px }
.pokesprite.genesect { background-position: -1400px -1566px }
.pokesprite.genesect-burn { background-position: -1470px -1566px }
.pokesprite.genesect-chill { background-position: -1540px -1566px }
.pokesprite.genesect-douse { background-position: -1610px -1566px }
.pokesprite.genesect-shock { background-position: -1680px -1566px }
.pokesprite.genesect-standard { background-position: -1750px -1566px }
.pokesprite.chespin { background-position: -1820px -1566px }
.pokesprite.quilladin { background-position: -1890px -1566px }
.pokesprite.chesnaught { background-position: -1960px -1566px }
.pokesprite.fennekin { background-position: -2030px -1566px }
.pokesprite.braixen { background-position: -2100px -1566px }
.pokesprite.delphox { background-position: -0px -1624px }
.pokesprite.froakie { background-position: -70px -1624px }
.pokesprite.frogadier { background-position: -140px -1624px }
.pokesprite.greninja { background-position: -210px -1624px }
.pokesprite.greninja-ash { background-position: -280px -1624px }
.pokesprite.greninja-battle-bond { background-position: -280px -1624px }
.pokesprite.bunnelby { background-position: -350px -1624px }
.pokesprite.diggersby { background-position: -420px -1624px }
.pokesprite.fletchling { background-position: -490px -1624px }
.pokesprite.fletchinder { background-position: -560px -1624px }
.pokesprite.talonflame { background-position: -630px -1624px }
.pokesprite.scatterbug { background-position: -700px -1624px }
.pokesprite.scatterbug-archipelago { background-position: -700px -1624px }
.pokesprite.scatterbug-continental { background-position: -700px -1624px }
.pokesprite.scatterbug-elegant { background-position: -700px -1624px }
.pokesprite.scatterbug-fancy { background-position: -700px -1624px }
.pokesprite.scatterbug-garden { background-position: -700px -1624px }
.pokesprite.scatterbug-high-plains { background-position: -700px -1624px }
.pokesprite.scatterbug-icy-snow { background-position: -700px -1624px }
.pokesprite.scatterbug-jungle { background-position: -700px -1624px }
.pokesprite.scatterbug-marine { background-position: -700px -1624px }
.pokesprite.scatterbug-meadow { background-position: -700px -1624px }
.pokesprite.scatterbug-modern { background-position: -700px -1624px }
.pokesprite.scatterbug-monsoon { background-position: -700px -1624px }
.pokesprite.scatterbug-ocean { background-position: -700px -1624px }
.pokesprite.scatterbug-poke-ball { background-position: -700px -1624px }
.pokesprite.scatterbug-polar { background-position: -700px -1624px }
.pokesprite.scatterbug-river { background-position: -700px -1624px }
.pokesprite.scatterbug-sandstorm { background-position: -700px -1624px }
.pokesprite.scatterbug-savanna { background-position: -700px -1624px }
.pokesprite.scatterbug-sun { background-position: -700px -1624px }
.pokesprite.scatterbug-tundra { background-position: -700px -1624px }
.pokesprite.spewpa { background-position: -770px -1624px }
.pokesprite.spewpa-archipelago { background-position: -770px -1624px }
.pokesprite.spewpa-continental { background-position: -770px -1624px }
.pokesprite.spewpa-elegant { background-position: -770px -1624px }
.pokesprite.spewpa-fancy { background-position: -770px -1624px }
.pokesprite.spewpa-garden { background-position: -770px -1624px }
.pokesprite.spewpa-high-plains { background-position: -770px -1624px }
.pokesprite.spewpa-icy-snow { background-position: -770px -1624px }
.pokesprite.spewpa-jungle { background-position: -770px -1624px }
.pokesprite.spewpa-marine { background-position: -770px -1624px }
.pokesprite.spewpa-meadow { background-position: -770px -1624px }
.pokesprite.spewpa-modern { background-position: -770px -1624px }
.pokesprite.spewpa-monsoon { background-position: -770px -1624px }
.pokesprite.spewpa-ocean { background-position: -770px -1624px }
.pokesprite.spewpa-poke-ball { background-position: -770px -1624px }
.pokesprite.spewpa-polar { background-position: -770px -1624px }
.pokesprite.spewpa-river { background-position: -770px -1624px }
.pokesprite.spewpa-sandstorm { background-position: -770px -1624px }
.pokesprite.spewpa-savanna { background-position: -770px -1624px }
.pokesprite.spewpa-sun { background-position: -770px -1624px }
.pokesprite.spewpa-tundra { background-position: -770px -1624px }
.pokesprite.vivillon { background-position: -840px -1624px }
.pokesprite.vivillon-meadow { background-position: -840px -1624px }
.pokesprite.vivillon-archipelago { background-position: -910px -1624px }
.pokesprite.vivillon-continental { background-position: -980px -1624px }
.pokesprite.vivillon-elegant { background-position: -1050px -1624px }
.pokesprite.vivillon-fancy { background-position: -1120px -1624px }
.pokesprite.vivillon-garden { background-position: -1190px -1624px }
.pokesprite.vivillon-high-plains { background-position: -1260px -1624px }
.pokesprite.vivillon-icy-snow { background-position: -1330px -1624px }
.pokesprite.vivillon-jungle { background-position: -1400px -1624px }
.pokesprite.vivillon-marine { background-position: -1470px -1624px }
.pokesprite.vivillon-modern { background-position: -1540px -1624px }
.pokesprite.vivillon-monsoon { background-position: -1610px -1624px }
.pokesprite.vivillon-ocean { background-position: -1680px -1624px }
.pokesprite.vivillon-poke-ball { background-position: -1750px -1624px }
.pokesprite.vivillon-polar { background-position: -1820px -1624px }
.pokesprite.vivillon-river { background-position: -1890px -1624px }
.pokesprite.vivillon-sandstorm { background-position: -1960px -1624px }
.pokesprite.vivillon-savanna { background-position: -2030px -1624px }
.pokesprite.vivillon-sun { background-position: -2100px -1624px }
.pokesprite.vivillon-tundra { background-position: -0px -1682px }
.pokesprite.litleo { background-position: -70px -1682px }
.pokesprite.pyroar { background-position: -140px -1682px }
.pokesprite.pyroar.female { background-position: -210px -1682px }
.pokesprite.flabebe { background-position: -280px -1682px }
.pokesprite.flabebe-red { background-position: -280px -1682px }
.pokesprite.flabebe-blue { background-position: -350px -1682px }
.pokesprite.flabebe-orange { background-position: -420px -1682px }
.pokesprite.flabebe-white { background-position: -490px -1682px }
.pokesprite.flabebe-yellow { background-position: -560px -1682px }
.pokesprite.floette { background-position: -630px -1682px }
.pokesprite.floette-red { background-position: -630px -1682px }
.pokesprite.floette-blue { background-position: -700px -1682px }
.pokesprite.floette-eternal { background-position: -770px -1682px }
.pokesprite.floette-orange { background-position: -840px -1682px }
.pokesprite.floette-white { background-position: -910px -1682px }
.pokesprite.floette-yellow { background-position: -980px -1682px }
.pokesprite.florges { background-position: -1050px -1682px }
.pokesprite.florges-red { background-position: -1050px -1682px }
.pokesprite.florges-blue { background-position: -1120px -1682px }
.pokesprite.florges-orange { background-position: -1190px -1682px }
.pokesprite.florges-white { background-position: -1260px -1682px }
.pokesprite.florges-yellow { background-position: -1330px -1682px }
.pokesprite.skiddo { background-position: -1400px -1682px }
.pokesprite.gogoat { background-position: -1470px -1682px }
.pokesprite.pancham { background-position: -1540px -1682px }
.pokesprite.pangoro { background-position: -1610px -1682px }
.pokesprite.furfrou { background-position: -1680px -1682px }
.pokesprite.furfrou-natural { background-position: -1680px -1682px }
.pokesprite.furfrou-dandy { background-position: -1750px -1682px }
.pokesprite.furfrou-debutante { background-position: -1820px -1682px }
.pokesprite.furfrou-diamond { background-position: -1890px -1682px }
.pokesprite.furfrou-heart { background-position: -1960px -1682px }
.pokesprite.furfrou-kabuki { background-position: -2030px -1682px }
.pokesprite.furfrou-la-reine { background-position: -2100px -1682px }
.pokesprite.furfrou-matron { background-position: -0px -1740px }
.pokesprite.furfrou-pharaoh { background-position: -70px -1740px }
.pokesprite.furfrou-star { background-position: -140px -1740px }
.pokesprite.espurr { background-position: -210px -1740px }
.pokesprite.meowstic { background-position: -280px -1740px }
.pokesprite.meowstic.female { background-position: -350px -1740px }
.pokesprite.honedge { background-position: -420px -1740px }
.pokesprite.doublade { background-position: -490px -1740px }
.pokesprite.aegislash { background-position: -560px -1740px }
.pokesprite.aegislash-shield { background-position: -560px -1740px }
.pokesprite.aegislash-blade { background-position: -630px -1740px }
.pokesprite.spritzee { background-position: -700px -1740px }
.pokesprite.aromatisse { background-position: -770px -1740px }
.pokesprite.swirlix { background-position: -840px -1740px }
.pokesprite.slurpuff { background-position: -910px -1740px }
.pokesprite.inkay { background-position: -980px -1740px }
.pokesprite.malamar { background-position: -1050px -1740px }
.pokesprite.binacle { background-position: -1120px -1740px }
.pokesprite.barbaracle { background-position: -1190px -1740px }
.pokesprite.skrelp { background-position: -1260px -1740px }
.pokesprite.dragalge { background-position: -1330px -1740px }
.pokesprite.clauncher { background-position: -1400px -1740px }
.pokesprite.clawitzer { background-position: -1470px -1740px }
.pokesprite.helioptile { background-position: -1540px -1740px }
.pokesprite.heliolisk { background-position: -1610px -1740px }
.pokesprite.tyrunt { background-position: -1680px -1740px }
.pokesprite.tyrantrum { background-position: -1750px -1740px }
.pokesprite.amaura { background-position: -1820px -1740px }
.pokesprite.aurorus { background-position: -1890px -1740px }
.pokesprite.sylveon { background-position: -1960px -1740px }
.pokesprite.hawlucha { background-position: -2030px -1740px }
.pokesprite.dedenne { background-position: -2100px -1740px }
.pokesprite.carbink { background-position: -0px -1798px }
.pokesprite.goomy { background-position: -70px -1798px }
.pokesprite.sliggoo { background-position: -140px -1798px }
.pokesprite.goodra { background-position: -210px -1798px }
.pokesprite.klefki { background-position: -280px -1798px }
.pokesprite.phantump { background-position: -350px -1798px }
.pokesprite.trevenant { background-position: -420px -1798px }
.pokesprite.pumpkaboo { background-position: -490px -1798px }
.pokesprite.pumpkaboo-average { background-position: -490px -1798px }
.pokesprite.pumpkaboo-large { background-position: -560px -1798px }
.pokesprite.pumpkaboo-small { background-position: -630px -1798px }
.pokesprite.pumpkaboo-super { background-position: -700px -1798px }
.pokesprite.gourgeist { background-position: -770px -1798px }
.pokesprite.gourgeist-average { background-position: -770px -1798px }
.pokesprite.gourgeist-large { background-position: -770px -1798px }
.pokesprite.gourgeist-small { background-position: -770px -1798px }
.pokesprite.gourgeist-super { background-position: -770px -1798px }
.pokesprite.bergmite { background-position: -840px -1798px }
.pokesprite.avalugg { background-position: -910px -1798px }
.pokesprite.noibat { background-position: -980px -1798px }
.pokesprite.noivern { background-position: -1050px -1798px }
.pokesprite.xerneas { background-position: -1120px -1798px }
.pokesprite.xerneas-neutral { background-position: -1120px -1798px }
.pokesprite.xerneas-active { background-position: -1190px -1798px }
.pokesprite.yveltal { background-position: -1260px -1798px }
.pokesprite.zygarde-10 { background-position: -1330px -1798px }
.pokesprite.zygarde { background-position: -1400px -1798px }
.pokesprite.zygarde-50 { background-position: -1400px -1798px }
.pokesprite.zygarde-complete { background-position: -1470px -1798px }
.pokesprite.diancie { background-position: -1540px -1798px }
.pokesprite.diancie-mega { background-position: -1610px -1798px }
.pokesprite.hoopa { background-position: -1680px -1798px }
.pokesprite.hoopa-unbound { background-position: -1750px -1798px }
.pokesprite.volcanion { background-position: -1820px -1798px }
.pokesprite.rowlet { background-position: -1890px -1798px }
.pokesprite.dartrix { background-position: -1960px -1798px }
.pokesprite.decidueye { background-position: -2030px -1798px }
.pokesprite.litten { background-position: -2100px -1798px }
.pokesprite.torracat { background-position: -0px -1856px }
.pokesprite.incineroar { background-position: -70px -1856px }
.pokesprite.popplio { background-position: -140px -1856px }
.pokesprite.brionne { background-position: -210px -1856px }
.pokesprite.primarina { background-position: -280px -1856px }
.pokesprite.pikipek { background-position: -350px -1856px }
.pokesprite.trumbeak { background-position: -420px -1856px }
.pokesprite.toucannon { background-position: -490px -1856px }
.pokesprite.yungoos { background-position: -560px -1856px }
.pokesprite.gumshoos { background-position: -630px -1856px }
.pokesprite.gumshoos-totem { background-position: -630px -1856px }
.pokesprite.grubbin { background-position: -700px -1856px }
.pokesprite.charjabug { background-position: -770px -1856px }
.pokesprite.vikavolt { background-position: -840px -1856px }
.pokesprite.vikavolt-totem { background-position: -840px -1856px }
.pokesprite.crabrawler { background-position: -910px -1856px }
.pokesprite.crabominable { background-position: -980px -1856px }
.pokesprite.oricorio { background-position: -1050px -1856px }
.pokesprite.oricorio-baile { background-position: -1050px -1856px }
.pokesprite.oricorio-pau { background-position: -1120px -1856px }
.pokesprite.oricorio-pom-pom { background-position: -1190px -1856px }
.pokesprite.oricorio-sensu { background-position: -1260px -1856px }
.pokesprite.cutiefly { background-position: -1330px -1856px }
.pokesprite.ribombee { background-position: -1400px -1856px }
.pokesprite.ribombee-totem { background-position: -1400px -1856px }
.pokesprite.rockruff { background-position: -1470px -1856px }
.pokesprite.rockruff-own-tempo { background-position: -1470px -1856px }
.pokesprite.lycanroc { background-position: -1540px -1856px }
.pokesprite.lycanroc-midday { background-position: -1540px -1856px }
.pokesprite.lycanroc-dusk { background-position: -1610px -1856px }
.pokesprite.lycanroc-midnight { background-position: -1680px -1856px }
.pokesprite.wishiwashi { background-position: -1750px -1856px }
.pokesprite.wishiwashi-solo { background-position: -1750px -1856px }
.pokesprite.wishiwashi-school { background-position: -1820px -1856px }
.pokesprite.mareanie { background-position: -1890px -1856px }
.pokesprite.toxapex { background-position: -1960px -1856px }
.pokesprite.mudbray { background-position: -2030px -1856px }
.pokesprite.mudsdale { background-position: -2100px -1856px }
.pokesprite.dewpider { background-position: -0px -1914px }
.pokesprite.araquanid { background-position: -70px -1914px }
.pokesprite.araquanid-totem { background-position: -70px -1914px }
.pokesprite.fomantis { background-position: -140px -1914px }
.pokesprite.lurantis { background-position: -210px -1914px }
.pokesprite.lurantis-totem { background-position: -210px -1914px }
.pokesprite.morelull { background-position: -280px -1914px }
.pokesprite.shiinotic { background-position: -350px -1914px }
.pokesprite.salandit { background-position: -420px -1914px }
.pokesprite.salazzle { background-position: -490px -1914px }
.pokesprite.salazzle-totem { background-position: -490px -1914px }
.pokesprite.stufful { background-position: -560px -1914px }
.pokesprite.bewear { background-position: -630px -1914px }
.pokesprite.bounsweet { background-position: -700px -1914px }
.pokesprite.steenee { background-position: -770px -1914px }
.pokesprite.tsareena { background-position: -840px -1914px }
.pokesprite.comfey { background-position: -910px -1914px }
.pokesprite.oranguru { background-position: -980px -1914px }
.pokesprite.passimian { background-position: -1050px -1914px }
.pokesprite.wimpod { background-position: -1120px -1914px }
.pokesprite.golisopod { background-position: -1190px -1914px }
.pokesprite.sandygast { background-position: -1260px -1914px }
.pokesprite.palossand { background-position: -1330px -1914px }
.pokesprite.pyukumuku { background-position: -1400px -1914px }
.pokesprite.type-null { background-position: -1470px -1914px }
.pokesprite.silvally { background-position: -1540px -1914px }
.pokesprite.silvally-normal { background-position: -1540px -1914px }
.pokesprite.silvally-bug { background-position: -1610px -1914px }
.pokesprite.silvally-dark { background-position: -1680px -1914px }
.pokesprite.silvally-dragon { background-position: -1750px -1914px }
.pokesprite.silvally-electric { background-position: -1820px -1914px }
.pokesprite.silvally-fairy { background-position: -1890px -1914px }
.pokesprite.silvally-fighting { background-position: -1960px -1914px }
.pokesprite.silvally-fire { background-position: -2030px -1914px }
.pokesprite.silvally-flying { background-position: -2100px -1914px }
.pokesprite.silvally-ghost { background-position: -0px -1972px }
.pokesprite.silvally-grass { background-position: -70px -1972px }
.pokesprite.silvally-ground { background-position: -140px -1972px }
.pokesprite.silvally-ice { background-position: -210px -1972px }
.pokesprite.silvally-poison { background-position: -280px -1972px }
.pokesprite.silvally-psychic { background-position: -350px -1972px }
.pokesprite.silvally-rock { background-position: -420px -1972px }
.pokesprite.silvally-steel { background-position: -490px -1972px }
.pokesprite.silvally-water { background-position: -560px -1972px }
.pokesprite.minior { background-position: -630px -1972px }
.pokesprite.minior-blue-meteor { background-position: -630px -1972px }
.pokesprite.minior-green-meteor { background-position: -630px -1972px }
.pokesprite.minior-indigo-meteor { background-position: -630px -1972px }
.pokesprite.minior-orange-meteor { background-position: -630px -1972px }
.pokesprite.minior-red-meteor { background-position: -630px -1972px }
.pokesprite.minior-violet-meteor { background-position: -630px -1972px }
.pokesprite.minior-yellow-meteor { background-position: -630px -1972px }
.pokesprite.minior-blue { background-position: -700px -1972px }
.pokesprite.minior-blue-gen7 { background-position: -770px -1972px }
.pokesprite.minior-green-gen7 { background-position: -770px -1972px }
.pokesprite.minior-indigo-gen7 { background-position: -770px -1972px }
.pokesprite.minior-orange-gen7 { background-position: -770px -1972px }
.pokesprite.minior-red-gen7 { background-position: -770px -1972px }
.pokesprite.minior-violet-gen7 { background-position: -770px -1972px }
.pokesprite.minior-yellow-gen7 { background-position: -770px -1972px }
.pokesprite.minior-green { background-position: -840px -1972px }
.pokesprite.minior-indigo { background-position: -910px -1972px }
.pokesprite.minior-orange { background-position: -980px -1972px }
.pokesprite.minior-red { background-position: -1050px -1972px }
.pokesprite.minior-violet { background-position: -1120px -1972px }
.pokesprite.minior-yellow { background-position: -1190px -1972px }
.pokesprite.komala { background-position: -1260px -1972px }
.pokesprite.turtonator { background-position: -1330px -1972px }
.pokesprite.togedemaru { background-position: -1400px -1972px }
.pokesprite.togedemaru-totem { background-position: -1400px -1972px }
.pokesprite.mimikyu { background-position: -1470px -1972px }
.pokesprite.mimikyu-busted { background-position: -1470px -1972px }
.pokesprite.mimikyu-disguised { background-position: -1470px -1972px }
.pokesprite.mimikyu-totem { background-position: -1470px -1972px }
.pokesprite.mimikyu-totem-busted { background-position: -1470px -1972px }
.pokesprite.mimikyu-totem-disguised { background-position: -1470px -1972px }
.pokesprite.bruxish { background-position: -1540px -1972px }
.pokesprite.drampa { background-position: -1610px -1972px }
.pokesprite.dhelmise { background-position: -1680px -1972px }
.pokesprite.jangmo-o { background-position: -1750px -1972px }
.pokesprite.hakamo-o { background-position: -1820px -1972px }
.pokesprite.kommo-o { background-position: -1890px -1972px }
.pokesprite.kommo-o-totem { background-position: -1890px -1972px }
.pokesprite.tapu-koko { background-position: -1960px -1972px }
.pokesprite.tapu-lele { background-position: -2030px -1972px }
.pokesprite.tapu-bulu { background-position: -2100px -1972px }
.pokesprite.tapu-fini { background-position: -0px -2030px }
.pokesprite.cosmog { background-position: -70px -2030px }
.pokesprite.cosmoem { background-position: -140px -2030px }
.pokesprite.solgaleo { background-position: -210px -2030px }
.pokesprite.lunala { background-position: -280px -2030px }
.pokesprite.nihilego { background-position: -350px -2030px }
.pokesprite.buzzwole { background-position: -420px -2030px }
.pokesprite.pheromosa { background-position: -490px -2030px }
.pokesprite.xurkitree { background-position: -560px -2030px }
.pokesprite.celesteela { background-position: -630px -2030px }
.pokesprite.kartana { background-position: -700px -2030px }
.pokesprite.guzzlord { background-position: -770px -2030px }
.pokesprite.necrozma { background-position: -840px -2030px }
.pokesprite.necrozma-dawn { background-position: -910px -2030px }
.pokesprite.necrozma-dusk { background-position: -980px -2030px }
.pokesprite.necrozma-ultra { background-position: -1050px -2030px }
.pokesprite.magearna { background-position: -1120px -2030px }
.pokesprite.magearna-original { background-position: -1190px -2030px }
.pokesprite.marshadow { background-position: -1260px -2030px }
.pokesprite.marshadow-gen7 { background-position: -1330px -2030px }
.pokesprite.poipole { background-position: -1400px -2030px }
.pokesprite.naganadel { background-position: -1470px -2030px }
.pokesprite.stakataka { background-position: -1540px -2030px }
.pokesprite.blacephalon { background-position: -1610px -2030px }
.pokesprite.zeraora { background-position: -1680px -2030px }
.pokesprite.meltan { background-position: -1750px -2030px }
.pokesprite.melmetal { background-position: -1820px -2030px }
.pokesprite.melmetal-gmax { background-position: -1890px -2030px }
.pokesprite.grookey { background-position: -1960px -2030px }
.pokesprite.thwackey { background-position: -2030px -2030px }
.pokesprite.rillaboom { background-position: -2100px -2030px }
.pokesprite.rillaboom-gmax { background-position: -0px -2088px }
.pokesprite.scorbunny { background-position: -70px -2088px }
.pokesprite.raboot { background-position: -140px -2088px }
.pokesprite.cinderace { background-position: -210px -2088px }
.pokesprite.cinderace-gmax { background-position: -280px -2088px }
.pokesprite.sobble { background-position: -350px -2088px }
.pokesprite.drizzile { background-position: -420px -2088px }
.pokesprite.inteleon { background-position: -490px -2088px }
.pokesprite.inteleon-gmax { background-position: -560px -2088px }
.pokesprite.skwovet { background-position: -630px -2088px }
.pokesprite.greedent { background-position: -700px -2088px }
.pokesprite.rookidee { background-position: -770px -2088px }
.pokesprite.corvisquire { background-position: -840px -2088px }
.pokesprite.corviknight { background-position: -910px -2088px }
.pokesprite.corviknight-gmax { background-position: -980px -2088px }
.pokesprite.blipbug { background-position: -1050px -2088px }
.pokesprite.dottler { background-position: -1120px -2088px }
.pokesprite.orbeetle { background-position: -1190px -2088px }
.pokesprite.orbeetle-gmax { background-position: -1260px -2088px }
.pokesprite.nickit { background-position: -1330px -2088px }
.pokesprite.thievul { background-position: -1400px -2088px }
.pokesprite.gossifleur { background-position: -1470px -2088px }
.pokesprite.eldegoss { background-position: -1540px -2088px }
.pokesprite.wooloo { background-position: -1610px -2088px }
.pokesprite.dubwool { background-position: -1680px -2088px }
.pokesprite.chewtle { background-position: -1750px -2088px }
.pokesprite.drednaw { background-position: -1820px -2088px }
.pokesprite.drednaw-gmax { background-position: -1890px -2088px }
.pokesprite.yamper { background-position: -1960px -2088px }
.pokesprite.boltund { background-position: -2030px -2088px }
.pokesprite.rolycoly { background-position: -2100px -2088px }
.pokesprite.carkol { background-position: -0px -2146px }
.pokesprite.coalossal { background-position: -70px -2146px }
.pokesprite.coalossal-gmax { background-position: -140px -2146px }
.pokesprite.applin { background-position: -210px -2146px }
.pokesprite.flapple { background-position: -280px -2146px }
.pokesprite.flapple-gmax { background-position: -350px -2146px }
.pokesprite.appletun { background-position: -420px -2146px }
.pokesprite.silicobra { background-position: -490px -2146px }
.pokesprite.sandaconda { background-position: -560px -2146px }
.pokesprite.sandaconda-gmax { background-position: -630px -2146px }
.pokesprite.cramorant { background-position: -700px -2146px }
.pokesprite.cramorant-gorging { background-position: -770px -2146px }
.pokesprite.cramorant-gulping { background-position: -840px -2146px }
.pokesprite.arrokuda { background-position: -910px -2146px }
.pokesprite.barraskewda { background-position: -980px -2146px }
.pokesprite.toxel { background-position: -1050px -2146px }
.pokesprite.toxtricity { background-position: -1120px -2146px }
.pokesprite.toxtricity-amped { background-position: -1120px -2146px }
.pokesprite.toxtricity-gmax { background-position: -1190px -2146px }
.pokesprite.toxtricity-low-key-gmax { background-position: -1190px -2146px }
.pokesprite.toxtricity-low-key { background-position: -1260px -2146px }
.pokesprite.sizzlipede { background-position: -1330px -2146px }
.pokesprite.centiskorch { background-position: -1400px -2146px }
.pokesprite.centiskorch-gmax { background-position: -1470px -2146px }
.pokesprite.clobbopus { background-position: -1540px -2146px }
.pokesprite.grapploct { background-position: -1610px -2146px }
.pokesprite.sinistea { background-position: -1680px -2146px }
.pokesprite.polteageist { background-position: -1750px -2146px }
.pokesprite.hatenna { background-position: -1820px -2146px }
.pokesprite.hattrem { background-position: -1890px -2146px }
.pokesprite.hatterene { background-position: -1960px -2146px }
.pokesprite.hatterene-gmax { background-position: -2030px -2146px }
.pokesprite.impidimp { background-position: -2100px -2146px }
.pokesprite.morgrem { background-position: -0px -2204px }
.pokesprite.grimmsnarl { background-position: -70px -2204px }
.pokesprite.grimmsnarl-gmax { background-position: -140px -2204px }
.pokesprite.obstagoon { background-position: -210px -2204px }
.pokesprite.perrserker { background-position: -280px -2204px }
.pokesprite.cursola { background-position: -350px -2204px }
.pokesprite.sirfetchd { background-position: -420px -2204px }
.pokesprite.mr-rime { background-position: -490px -2204px }
.pokesprite.runerigus { background-position: -560px -2204px }
.pokesprite.milcery { background-position: -630px -2204px }
.pokesprite.alcremie { background-position: -700px -2204px }
.pokesprite.alcremie-caramel-swirl-berry { background-position: -770px -2204px }
.pokesprite.alcremie-caramel-swirl-clover { background-position: -840px -2204px }
.pokesprite.alcremie-caramel-swirl-flower { background-position: -910px -2204px }
.pokesprite.alcremie-caramel-swirl-love { background-position: -980px -2204px }
.pokesprite.alcremie-caramel-swirl-plain { background-position: -1050px -2204px }
.pokesprite.alcremie-caramel-swirl-ribbon { background-position: -1120px -2204px }
.pokesprite.alcremie-caramel-swirl-star { background-position: -1190px -2204px }
.pokesprite.alcremie-caramel-swirl-strawberry { background-position: -1260px -2204px }
.pokesprite.alcremie-gmax { background-position: -1330px -2204px }
.pokesprite.alcremie-lemon-cream-berry { background-position: -1400px -2204px }
.pokesprite.alcremie-lemon-cream-clover { background-position: -1470px -2204px }
.pokesprite.alcremie-lemon-cream-flower { background-position: -1540px -2204px }
.pokesprite.alcremie-lemon-cream-love { background-position: -1610px -2204px }
.pokesprite.alcremie-lemon-cream-plain { background-position: -1680px -2204px }
.pokesprite.alcremie-lemon-cream-ribbon { background-position: -1750px -2204px }
.pokesprite.alcremie-lemon-cream-star { background-position: -1820px -2204px }
.pokesprite.alcremie-lemon-cream-strawberry { background-position: -1890px -2204px }
.pokesprite.alcremie-matcha-cream-berry { background-position: -1960px -2204px }
.pokesprite.alcremie-matcha-cream-clover { background-position: -2030px -2204px }
.pokesprite.alcremie-matcha-cream-flower { background-position: -2100px -2204px }
.pokesprite.alcremie-matcha-cream-love { background-position: -0px -2262px }
.pokesprite.alcremie-matcha-cream-plain { background-position: -70px -2262px }
.pokesprite.alcremie-matcha-cream-ribbon { background-position: -140px -2262px }
.pokesprite.alcremie-matcha-cream-star { background-position: -210px -2262px }
.pokesprite.alcremie-matcha-cream-strawberry { background-position: -280px -2262px }
.pokesprite.alcremie-mint-cream-berry { background-position: -350px -2262px }
.pokesprite.alcremie-mint-cream-clover { background-position: -420px -2262px }
.pokesprite.alcremie-mint-cream-flower { background-position: -490px -2262px }
.pokesprite.alcremie-mint-cream-love { background-position: -560px -2262px }
.pokesprite.alcremie-mint-cream-plain { background-position: -630px -2262px }
.pokesprite.alcremie-mint-cream-ribbon { background-position: -700px -2262px }
.pokesprite.alcremie-mint-cream-star { background-position: -770px -2262px }
.pokesprite.alcremie-mint-cream-strawberry { background-position: -840px -2262px }
.pokesprite.alcremie-rainbow-swirl-berry { background-position: -910px -2262px }
.pokesprite.alcremie-rainbow-swirl-clover { background-position: -980px -2262px }
.pokesprite.alcremie-rainbow-swirl-flower { background-position: -1050px -2262px }
.pokesprite.alcremie-rainbow-swirl-love { background-position: -1120px -2262px }
.pokesprite.alcremie-rainbow-swirl-plain { background-position: -1190px -2262px }
.pokesprite.alcremie-rainbow-swirl-ribbon { background-position: -1260px -2262px }
.pokesprite.alcremie-rainbow-swirl-star { background-position: -1330px -2262px }
.pokesprite.alcremie-rainbow-swirl-strawberry { background-position: -1400px -2262px }
.pokesprite.alcremie-ruby-cream-berry { background-position: -1470px -2262px }
.pokesprite.alcremie-ruby-cream-clover { background-position: -1540px -2262px }
.pokesprite.alcremie-ruby-cream-flower { background-position: -1610px -2262px }
.pokesprite.alcremie-ruby-cream-love { background-position: -1680px -2262px }
.pokesprite.alcremie-ruby-cream-plain { background-position: -1750px -2262px }
.pokesprite.alcremie-ruby-cream-ribbon { background-position: -1820px -2262px }
.pokesprite.alcremie-ruby-cream-star { background-position: -1890px -2262px }
.pokesprite.alcremie-ruby-cream-strawberry { background-position: -1960px -2262px }
.pokesprite.alcremie-ruby-swirl-berry { background-position: -2030px -2262px }
.pokesprite.alcremie-ruby-swirl-clover { background-position: -2100px -2262px }
.pokesprite.alcremie-ruby-swirl-flower { background-position: -0px -2320px }
.pokesprite.alcremie-ruby-swirl-love { background-position: -70px -2320px }
.pokesprite.alcremie-ruby-swirl-plain { background-position: -140px -2320px }
.pokesprite.alcremie-ruby-swirl-ribbon { background-position: -210px -2320px }
.pokesprite.alcremie-ruby-swirl-star { background-position: -280px -2320px }
.pokesprite.alcremie-ruby-swirl-strawberry { background-position: -350px -2320px }
.pokesprite.alcremie-salted-cream-berry { background-position: -420px -2320px }
.pokesprite.alcremie-salted-cream-clover { background-position: -490px -2320px }
.pokesprite.alcremie-salted-cream-flower { background-position: -560px -2320px }
.pokesprite.alcremie-salted-cream-love { background-position: -630px -2320px }
.pokesprite.alcremie-salted-cream-plain { background-position: -700px -2320px }
.pokesprite.alcremie-salted-cream-ribbon { background-position: -770px -2320px }
.pokesprite.alcremie-salted-cream-star { background-position: -840px -2320px }
.pokesprite.alcremie-salted-cream-strawberry { background-position: -910px -2320px }
.pokesprite.alcremie-vanilla-cream-berry { background-position: -980px -2320px }
.pokesprite.alcremie-vanilla-cream-clover { background-position: -1050px -2320px }
.pokesprite.alcremie-vanilla-cream-flower { background-position: -1120px -2320px }
.pokesprite.alcremie-vanilla-cream-love { background-position: -1190px -2320px }
.pokesprite.alcremie-vanilla-cream-plain { background-position: -1260px -2320px }
.pokesprite.alcremie-vanilla-cream-ribbon { background-position: -1330px -2320px }
.pokesprite.alcremie-vanilla-cream-star { background-position: -1400px -2320px }
.pokesprite.alcremie-vanilla-cream-strawberry { background-position: -1470px -2320px }
.pokesprite.falinks { background-position: -1540px -2320px }
.pokesprite.pincurchin { background-position: -1610px -2320px }
.pokesprite.snom { background-position: -1680px -2320px }
.pokesprite.frosmoth { background-position: -1750px -2320px }
.pokesprite.stonjourner { background-position: -1820px -2320px }
.pokesprite.eiscue { background-position: -1890px -2320px }
.pokesprite.eiscue-ice { background-position: -1890px -2320px }
.pokesprite.eiscue-noice { background-position: -1960px -2320px }
.pokesprite.indeedee { background-position: -2030px -2320px }
.pokesprite.indeedee.female { background-position: -2100px -2320px }
.pokesprite.morpeko { background-position: -0px -2378px }
.pokesprite.morpeko-full-belly { background-position: -0px -2378px }
.pokesprite.morpeko-hangry { background-position: -70px -2378px }
.pokesprite.cufant { background-position: -140px -2378px }
.pokesprite.copperajah { background-position: -210px -2378px }
.pokesprite.copperajah-gmax { background-position: -280px -2378px }
.pokesprite.dracozolt { background-position: -350px -2378px }
.pokesprite.arctozolt { background-position: -420px -2378px }
.pokesprite.dracovish { background-position: -490px -2378px }
.pokesprite.arctovish { background-position: -560px -2378px }
.pokesprite.duraludon { background-position: -630px -2378px }
.pokesprite.duraludon-gmax { background-position: -700px -2378px }
.pokesprite.dreepy { background-position: -770px -2378px }
.pokesprite.drakloak { background-position: -840px -2378px }
.pokesprite.dragapult { background-position: -910px -2378px }
.pokesprite.zacian { background-position: -980px -2378px }
.pokesprite.zacian-hero-of-many-battles { background-position: -980px -2378px }
.pokesprite.zacian-crowned { background-position: -1050px -2378px }
.pokesprite.zamazenta { background-position: -1120px -2378px }
.pokesprite.zamazenta-hero-of-many-battles { background-position: -1120px -2378px }
.pokesprite.zamazenta-crowned { background-position: -1190px -2378px }
.pokesprite.eternatus { background-position: -1260px -2378px }
.pokesprite.eternatus-eternamax { background-position: -1330px -2378px }
.pokesprite.kubfu { background-position: -1400px -2378px }
.pokesprite.urshifu { background-position: -1470px -2378px }
.pokesprite.urshifu-gmax { background-position: -1540px -2378px }
.pokesprite.urshifu-single-strike-gmax { background-position: -1540px -2378px }
.pokesprite.urshifu-rapid-strike-gmax { background-position: -1610px -2378px }
.pokesprite.zarude { background-position: -1680px -2378px }
.pokesprite.zarude-dada { background-position: -1750px -2378px }
.pokesprite.bulbasaur.shiny { background-position: -1820px -2378px }
.pokesprite.ivysaur.shiny { background-position: -1890px -2378px }
.pokesprite.venusaur.shiny { background-position: -1960px -2378px }
.pokesprite.venusaur-mega.shiny { background-position: -2030px -2378px }
.pokesprite.venusaur-gmax.shiny { background-position: -2100px -2378px }
.pokesprite.charmander.shiny { background-position: -0px -2436px }
.pokesprite.charmeleon.shiny { background-position: -70px -2436px }
.pokesprite.charizard.shiny { background-position: -140px -2436px }
.pokesprite.charizard-gmax.shiny { background-position: -210px -2436px }
.pokesprite.charizard-mega-x.shiny { background-position: -280px -2436px }
.pokesprite.charizard-mega-y.shiny { background-position: -350px -2436px }
.pokesprite.squirtle.shiny { background-position: -420px -2436px }
.pokesprite.wartortle.shiny { background-position: -490px -2436px }
.pokesprite.blastoise.shiny { background-position: -560px -2436px }
.pokesprite.blastoise-mega.shiny { background-position: -630px -2436px }
.pokesprite.blastoise-gmax.shiny { background-position: -700px -2436px }
.pokesprite.caterpie.shiny { background-position: -770px -2436px }
.pokesprite.metapod.shiny { background-position: -840px -2436px }
.pokesprite.butterfree.shiny { background-position: -910px -2436px }
.pokesprite.butterfree-gmax.shiny { background-position: -980px -2436px }
.pokesprite.weedle.shiny { background-position: -1050px -2436px }
.pokesprite.kakuna.shiny { background-position: -1120px -2436px }
.pokesprite.beedrill.shiny { background-position: -1190px -2436px }
.pokesprite.beedrill-mega.shiny { background-position: -1260px -2436px }
.pokesprite.pidgey.shiny { background-position: -1330px -2436px }
.pokesprite.pidgeotto.shiny { background-position: -1400px -2436px }
.pokesprite.pidgeot.shiny { background-position: -1470px -2436px }
.pokesprite.pidgeot-mega.shiny { background-position: -1540px -2436px }
.pokesprite.rattata.shiny { background-position: -1610px -2436px }
.pokesprite.rattata-alola.shiny { background-position: -1680px -2436px }
.pokesprite.raticate.shiny { background-position: -1750px -2436px }
.pokesprite.raticate-alola.shiny { background-position: -1820px -2436px }
.pokesprite.raticate-totem.shiny { background-position: -1820px -2436px }
.pokesprite.raticate-totem-alola.shiny { background-position: -1820px -2436px }
.pokesprite.spearow.shiny { background-position: -1890px -2436px }
.pokesprite.fearow.shiny { background-position: -1960px -2436px }
.pokesprite.ekans.shiny { background-position: -2030px -2436px }
.pokesprite.arbok.shiny { background-position: -2100px -2436px }
.pokesprite.pikachu.shiny { background-position: -0px -2494px }
.pokesprite.pikachu.shiny.female { background-position: -70px -2494px }
.pokesprite.pikachu-alola-cap.shiny { background-position: -140px -2494px }
.pokesprite.pikachu-alola-cap.shiny.female { background-position: -210px -2494px }
.pokesprite.pikachu-belle.shiny { background-position: -280px -2494px }
.pokesprite.pikachu-cosplay.shiny { background-position: -350px -2494px }
.pokesprite.pikachu-gmax.shiny { background-position: -420px -2494px }
.pokesprite.pikachu-hoenn-cap.shiny { background-position: -490px -2494px }
.pokesprite.pikachu-hoenn-cap.shiny.female { background-position: -560px -2494px }
.pokesprite.pikachu-kalos-cap.shiny { background-position: -630px -2494px }
.pokesprite.pikachu-kalos-cap.shiny.female { background-position: -700px -2494px }
.pokesprite.pikachu-libre.shiny { background-position: -770px -2494px }
.pokesprite.pikachu-original-cap.shiny { background-position: -840px -2494px }
.pokesprite.pikachu-original-cap.shiny.female { background-position: -910px -2494px }
.pokesprite.pikachu-partner-cap.shiny { background-position: -980px -2494px }
.pokesprite.pikachu-partner-cap.shiny.female { background-position: -1050px -2494px }
.pokesprite.pikachu-phd.shiny { background-position: -1120px -2494px }
.pokesprite.pikachu-pop-star.shiny { background-position: -1190px -2494px }
.pokesprite.pikachu-rock-star.shiny { background-position: -1260px -2494px }
.pokesprite.pikachu-sinnoh-cap.shiny { background-position: -1330px -2494px }
.pokesprite.pikachu-sinnoh-cap.shiny.female { background-position: -1400px -2494px }
.pokesprite.pikachu-starter.shiny { background-position: -1470px -2494px }
.pokesprite.pikachu-starter.shiny.female { background-position: -1540px -2494px }
.pokesprite.pikachu-unova-cap.shiny { background-position: -1610px -2494px }
.pokesprite.pikachu-unova-cap.shiny.female { background-position: -1680px -2494px }
.pokesprite.pikachu-world-cap.shiny { background-position: -1750px -2494px }
.pokesprite.pikachu-world-cap.shiny.female { background-position: -1820px -2494px }
.pokesprite.raichu.shiny { background-position: -1890px -2494px }
.pokesprite.raichu-alola.shiny { background-position: -1960px -2494px }
.pokesprite.sandshrew.shiny { background-position: -2030px -2494px }
.pokesprite.sandshrew-alola.shiny { background-position: -2100px -2494px }
.pokesprite.sandslash.shiny { background-position: -0px -2552px }
.pokesprite.sandslash-alola.shiny { background-position: -70px -2552px }
.pokesprite.nidoran-f.shiny { background-position: -140px -2552px }
.pokesprite.nidorina.shiny { background-position: -210px -2552px }
.pokesprite.nidoqueen.shiny { background-position: -280px -2552px }
.pokesprite.nidoran-m.shiny { background-position: -350px -2552px }
.pokesprite.nidorino.shiny { background-position: -420px -2552px }
.pokesprite.nidoking.shiny { background-position: -490px -2552px }
.pokesprite.clefairy.shiny { background-position: -560px -2552px }
.pokesprite.clefable.shiny { background-position: -630px -2552px }
.pokesprite.vulpix.shiny { background-position: -700px -2552px }
.pokesprite.vulpix-alola.shiny { background-position: -770px -2552px }
.pokesprite.ninetales.shiny { background-position: -840px -2552px }
.pokesprite.ninetales-alola.shiny { background-position: -910px -2552px }
.pokesprite.jigglypuff.shiny { background-position: -980px -2552px }
.pokesprite.wigglytuff.shiny { background-position: -1050px -2552px }
.pokesprite.zubat.shiny { background-position: -1120px -2552px }
.pokesprite.golbat.shiny { background-position: -1190px -2552px }
.pokesprite.oddish.shiny { background-position: -1260px -2552px }
.pokesprite.gloom.shiny { background-position: -1330px -2552px }
.pokesprite.vileplume.shiny { background-position: -1400px -2552px }
.pokesprite.paras.shiny { background-position: -1470px -2552px }
.pokesprite.parasect.shiny { background-position: -1540px -2552px }
.pokesprite.venonat.shiny { background-position: -1610px -2552px }
.pokesprite.venomoth.shiny { background-position: -1680px -2552px }
.pokesprite.diglett.shiny { background-position: -1750px -2552px }
.pokesprite.diglett-alola.shiny { background-position: -1820px -2552px }
.pokesprite.dugtrio.shiny { background-position: -1890px -2552px }
.pokesprite.dugtrio-alola.shiny { background-position: -1960px -2552px }
.pokesprite.meowth.shiny { background-position: -2030px -2552px }
.pokesprite.meowth-alola.shiny { background-position: -2100px -2552px }
.pokesprite.meowth-galar.shiny { background-position: -0px -2610px }
.pokesprite.meowth-gmax.shiny { background-position: -70px -2610px }
.pokesprite.persian.shiny { background-position: -140px -2610px }
.pokesprite.persian-alola.shiny { background-position: -210px -2610px }
.pokesprite.psyduck.shiny { background-position: -280px -2610px }
.pokesprite.golduck.shiny { background-position: -350px -2610px }
.pokesprite.mankey.shiny { background-position: -420px -2610px }
.pokesprite.primeape.shiny { background-position: -490px -2610px }
.pokesprite.growlithe.shiny { background-position: -560px -2610px }
.pokesprite.arcanine.shiny { background-position: -630px -2610px }
.pokesprite.poliwag.shiny { background-position: -700px -2610px }
.pokesprite.poliwhirl.shiny { background-position: -770px -2610px }
.pokesprite.poliwrath.shiny { background-position: -840px -2610px }
.pokesprite.abra.shiny { background-position: -910px -2610px }
.pokesprite.kadabra.shiny { background-position: -980px -2610px }
.pokesprite.alakazam.shiny { background-position: -1050px -2610px }
.pokesprite.alakazam-mega.shiny { background-position: -1120px -2610px }
.pokesprite.machop.shiny { background-position: -1190px -2610px }
.pokesprite.machoke.shiny { background-position: -1260px -2610px }
.pokesprite.machamp.shiny { background-position: -1330px -2610px }
.pokesprite.machamp-gmax.shiny { background-position: -1400px -2610px }
.pokesprite.bellsprout.shiny { background-position: -1470px -2610px }
.pokesprite.weepinbell.shiny { background-position: -1540px -2610px }
.pokesprite.victreebel.shiny { background-position: -1610px -2610px }
.pokesprite.tentacool.shiny { background-position: -1680px -2610px }
.pokesprite.tentacruel.shiny { background-position: -1750px -2610px }
.pokesprite.geodude.shiny { background-position: -1820px -2610px }
.pokesprite.geodude-alola.shiny { background-position: -1890px -2610px }
.pokesprite.graveler.shiny { background-position: -1960px -2610px }
.pokesprite.graveler-alola.shiny { background-position: -2030px -2610px }
.pokesprite.golem.shiny { background-position: -2100px -2610px }
.pokesprite.golem-alola.shiny { background-position: -0px -2668px }
.pokesprite.ponyta.shiny { background-position: -70px -2668px }
.pokesprite.ponyta-galar.shiny { background-position: -140px -2668px }
.pokesprite.rapidash.shiny { background-position: -210px -2668px }
.pokesprite.rapidash-galar.shiny { background-position: -280px -2668px }
.pokesprite.slowpoke.shiny { background-position: -350px -2668px }
.pokesprite.slowpoke-galar.shiny { background-position: -420px -2668px }
.pokesprite.slowbro.shiny { background-position: -490px -2668px }
.pokesprite.slowbro-mega.shiny { background-position: -560px -2668px }
.pokesprite.slowbro-galar.shiny { background-position: -630px -2668px }
.pokesprite.magnemite.shiny { background-position: -700px -2668px }
.pokesprite.magneton.shiny { background-position: -770px -2668px }
.pokesprite.farfetchd.shiny { background-position: -840px -2668px }
.pokesprite.farfetchd-galar.shiny { background-position: -910px -2668px }
.pokesprite.doduo.shiny { background-position: -980px -2668px }
.pokesprite.dodrio.shiny { background-position: -1050px -2668px }
.pokesprite.seel.shiny { background-position: -1120px -2668px }
.pokesprite.dewgong.shiny { background-position: -1190px -2668px }
.pokesprite.grimer.shiny { background-position: -1260px -2668px }
.pokesprite.grimer-alola.shiny { background-position: -1330px -2668px }
.pokesprite.muk.shiny { background-position: -1400px -2668px }
.pokesprite.muk-alola.shiny { background-position: -1470px -2668px }
.pokesprite.shellder.shiny { background-position: -1540px -2668px }
.pokesprite.cloyster.shiny { background-position: -1610px -2668px }
.pokesprite.gastly.shiny { background-position: -1680px -2668px }
.pokesprite.haunter.shiny { background-position: -1750px -2668px }
.pokesprite.gengar.shiny { background-position: -1820px -2668px }
.pokesprite.gengar-gmax.shiny { background-position: -1890px -2668px }
.pokesprite.gengar-mega.shiny { background-position: -1960px -2668px }
.pokesprite.onix.shiny { background-position: -2030px -2668px }
.pokesprite.drowzee.shiny { background-position: -2100px -2668px }
.pokesprite.hypno.shiny { background-position: -0px -2726px }
.pokesprite.krabby.shiny { background-position: -70px -2726px }
.pokesprite.kingler.shiny { background-position: -140px -2726px }
.pokesprite.kingler-gmax.shiny { background-position: -210px -2726px }
.pokesprite.voltorb.shiny { background-position: -280px -2726px }
.pokesprite.electrode.shiny { background-position: -350px -2726px }
.pokesprite.exeggcute.shiny { background-position: -420px -2726px }
.pokesprite.exeggutor.shiny { background-position: -490px -2726px }
.pokesprite.exeggutor-alola.shiny { background-position: -560px -2726px }
.pokesprite.cubone.shiny { background-position: -630px -2726px }
.pokesprite.marowak.shiny { background-position: -700px -2726px }
.pokesprite.marowak-alola.shiny { background-position: -770px -2726px }
.pokesprite.marowak-totem.shiny { background-position: -770px -2726px }
.pokesprite.marowak-totem-alola.shiny { background-position: -770px -2726px }
.pokesprite.hitmonlee.shiny { background-position: -840px -2726px }
.pokesprite.hitmonchan.shiny { background-position: -910px -2726px }
.pokesprite.lickitung.shiny { background-position: -980px -2726px }
.pokesprite.koffing.shiny { background-position: -1050px -2726px }
.pokesprite.weezing.shiny { background-position: -1120px -2726px }
.pokesprite.weezing-galar.shiny { background-position: -1190px -2726px }
.pokesprite.rhyhorn.shiny { background-position: -1260px -2726px }
.pokesprite.rhydon.shiny { background-position: -1330px -2726px }
.pokesprite.chansey.shiny { background-position: -1400px -2726px }
.pokesprite.tangela.shiny { background-position: -1470px -2726px }
.pokesprite.kangaskhan.shiny { background-position: -1540px -2726px }
.pokesprite.kangaskhan-mega.shiny { background-position: -1610px -2726px }
.pokesprite.horsea.shiny { background-position: -1680px -2726px }
.pokesprite.seadra.shiny { background-position: -1750px -2726px }
.pokesprite.goldeen.shiny { background-position: -1820px -2726px }
.pokesprite.seaking.shiny { background-position: -1890px -2726px }
.pokesprite.staryu.shiny { background-position: -1960px -2726px }
.pokesprite.starmie.shiny { background-position: -2030px -2726px }
.pokesprite.mr-mime.shiny { background-position: -2100px -2726px }
.pokesprite.mr-mime-galar.shiny { background-position: -0px -2784px }
.pokesprite.scyther.shiny { background-position: -70px -2784px }
.pokesprite.jynx.shiny { background-position: -140px -2784px }
.pokesprite.electabuzz.shiny { background-position: -210px -2784px }
.pokesprite.magmar.shiny { background-position: -280px -2784px }
.pokesprite.pinsir.shiny { background-position: -350px -2784px }
.pokesprite.pinsir-mega.shiny { background-position: -420px -2784px }
.pokesprite.tauros.shiny { background-position: -490px -2784px }
.pokesprite.magikarp.shiny { background-position: -560px -2784px }
.pokesprite.gyarados.shiny { background-position: -630px -2784px }
.pokesprite.gyarados-mega.shiny { background-position: -700px -2784px }
.pokesprite.lapras.shiny { background-position: -770px -2784px }
.pokesprite.lapras-gmax.shiny { background-position: -840px -2784px }
.pokesprite.ditto.shiny { background-position: -910px -2784px }
.pokesprite.eevee.shiny { background-position: -980px -2784px }
.pokesprite.eevee-gmax.shiny { background-position: -1050px -2784px }
.pokesprite.eevee-starter.shiny { background-position: -1120px -2784px }
.pokesprite.vaporeon.shiny { background-position: -1190px -2784px }
.pokesprite.jolteon.shiny { background-position: -1260px -2784px }
.pokesprite.flareon.shiny { background-position: -1330px -2784px }
.pokesprite.porygon.shiny { background-position: -1400px -2784px }
.pokesprite.omanyte.shiny { background-position: -1470px -2784px }
.pokesprite.omastar.shiny { background-position: -1540px -2784px }
.pokesprite.kabuto.shiny { background-position: -1610px -2784px }
.pokesprite.kabutops.shiny { background-position: -1680px -2784px }
.pokesprite.aerodactyl.shiny { background-position: -1750px -2784px }
.pokesprite.aerodactyl-mega.shiny { background-position: -1820px -2784px }
.pokesprite.snorlax.shiny { background-position: -1890px -2784px }
.pokesprite.snorlax-gmax.shiny { background-position: -1960px -2784px }
.pokesprite.articuno.shiny { background-position: -2030px -2784px }
.pokesprite.zapdos.shiny { background-position: -2100px -2784px }
.pokesprite.moltres.shiny { background-position: -0px -2842px }
.pokesprite.dratini.shiny { background-position: -70px -2842px }
.pokesprite.dragonair.shiny { background-position: -140px -2842px }
.pokesprite.dragonite.shiny { background-position: -210px -2842px }
.pokesprite.mewtwo.shiny { background-position: -280px -2842px }
.pokesprite.mewtwo-mega-x.shiny { background-position: -350px -2842px }
.pokesprite.mewtwo-mega-y.shiny { background-position: -420px -2842px }
.pokesprite.mew.shiny { background-position: -490px -2842px }
.pokesprite.chikorita.shiny { background-position: -560px -2842px }
.pokesprite.bayleef.shiny { background-position: -630px -2842px }
.pokesprite.meganium.shiny { background-position: -700px -2842px }
.pokesprite.cyndaquil.shiny { background-position: -770px -2842px }
.pokesprite.quilava.shiny { background-position: -840px -2842px }
.pokesprite.typhlosion.shiny { background-position: -910px -2842px }
.pokesprite.totodile.shiny { background-position: -980px -2842px }
.pokesprite.croconaw.shiny { background-position: -1050px -2842px }
.pokesprite.feraligatr.shiny { background-position: -1120px -2842px }
.pokesprite.sentret.shiny { background-position: -1190px -2842px }
.pokesprite.furret.shiny { background-position: -1260px -2842px }
.pokesprite.hoothoot.shiny { background-position: -1330px -2842px }
.pokesprite.noctowl.shiny { background-position: -1400px -2842px }
.pokesprite.ledyba.shiny { background-position: -1470px -2842px }
.pokesprite.ledian.shiny { background-position: -1540px -2842px }
.pokesprite.spinarak.shiny { background-position: -1610px -2842px }
.pokesprite.ariados.shiny { background-position: -1680px -2842px }
.pokesprite.crobat.shiny { background-position: -1750px -2842px }
.pokesprite.chinchou.shiny { background-position: -1820px -2842px }
.pokesprite.lanturn.shiny { background-position: -1890px -2842px }
.pokesprite.pichu.shiny { background-position: -1960px -2842px }
.pokesprite.pichu-spiky-eared.shiny { background-position: -2030px -2842px }
.pokesprite.cleffa.shiny { background-position: -2100px -2842px }
.pokesprite.igglybuff.shiny { background-position: -0px -2900px }
.pokesprite.togepi.shiny { background-position: -70px -2900px }
.pokesprite.togetic.shiny { background-position: -140px -2900px }
.pokesprite.natu.shiny { background-position: -210px -2900px }
.pokesprite.xatu.shiny { background-position: -280px -2900px }
.pokesprite.mareep.shiny { background-position: -350px -2900px }
.pokesprite.flaaffy.shiny { background-position: -420px -2900px }
.pokesprite.ampharos.shiny { background-position: -490px -2900px }
.pokesprite.ampharos-mega.shiny { background-position: -560px -2900px }
.pokesprite.bellossom.shiny { background-position: -630px -2900px }
.pokesprite.marill.shiny { background-position: -700px -2900px }
.pokesprite.azumarill.shiny { background-position: -770px -2900px }
.pokesprite.sudowoodo.shiny { background-position: -840px -2900px }
.pokesprite.politoed.shiny { background-position: -910px -2900px }
.pokesprite.hoppip.shiny { background-position: -980px -2900px }
.pokesprite.skiploom.shiny { background-position: -1050px -2900px }
.pokesprite.jumpluff.shiny { background-position: -1120px -2900px }
.pokesprite.aipom.shiny { background-position: -1190px -2900px }
.pokesprite.sunkern.shiny { background-position: -1260px -2900px }
.pokesprite.sunflora.shiny { background-position: -1330px -2900px }
.pokesprite.yanma.shiny { background-position: -1400px -2900px }
.pokesprite.wooper.shiny { background-position: -1470px -2900px }
.pokesprite.quagsire.shiny { background-position: -1540px -2900px }
.pokesprite.espeon.shiny { background-position: -1610px -2900px }
.pokesprite.umbreon.shiny { background-position: -1680px -2900px }
.pokesprite.murkrow.shiny { background-position: -1750px -2900px }
.pokesprite.slowking.shiny { background-position: -1820px -2900px }
.pokesprite.misdreavus.shiny { background-position: -1890px -2900px }
.pokesprite.unown.shiny { background-position: -1960px -2900px }
.pokesprite.unown-a.shiny { background-position: -1960px -2900px }
.pokesprite.unown-b.shiny { background-position: -2030px -2900px }
.pokesprite.unown-c.shiny { background-position: -2100px -2900px }
.pokesprite.unown-d.shiny { background-position: -0px -2958px }
.pokesprite.unown-e.shiny { background-position: -70px -2958px }
.pokesprite.unown-exclamation.shiny { background-position: -140px -2958px }
.pokesprite.unown-f.shiny { background-position: -210px -2958px }
.pokesprite.unown-g.shiny { background-position: -280px -2958px }
.pokesprite.unown-h.shiny { background-position: -350px -2958px }
.pokesprite.unown-i.shiny { background-position: -420px -2958px }
.pokesprite.unown-j.shiny { background-position: -490px -2958px }
.pokesprite.unown-k.shiny { background-position: -560px -2958px }
.pokesprite.unown-l.shiny { background-position: -630px -2958px }
.pokesprite.unown-m.shiny { background-position: -700px -2958px }
.pokesprite.unown-n.shiny { background-position: -770px -2958px }
.pokesprite.unown-o.shiny { background-position: -840px -2958px }
.pokesprite.unown-p.shiny { background-position: -910px -2958px }
.pokesprite.unown-q.shiny { background-position: -980px -2958px }
.pokesprite.unown-question.shiny { background-position: -1050px -2958px }
.pokesprite.unown-r.shiny { background-position: -1120px -2958px }
.pokesprite.unown-s.shiny { background-position: -1190px -2958px }
.pokesprite.unown-t.shiny { background-position: -1260px -2958px }
.pokesprite.unown-u.shiny { background-position: -1330px -2958px }
.pokesprite.unown-v.shiny { background-position: -1400px -2958px }
.pokesprite.unown-w.shiny { background-position: -1470px -2958px }
.pokesprite.unown-x.shiny { background-position: -1540px -2958px }
.pokesprite.unown-y.shiny { background-position: -1610px -2958px }
.pokesprite.unown-z.shiny { background-position: -1680px -2958px }
.pokesprite.wobbuffet.shiny { background-position: -1750px -2958px }
.pokesprite.girafarig.shiny { background-position: -1820px -2958px }
.pokesprite.pineco.shiny { background-position: -1890px -2958px }
.pokesprite.forretress.shiny { background-position: -1960px -2958px }
.pokesprite.dunsparce.shiny { background-position: -2030px -2958px }
.pokesprite.gligar.shiny { background-position: -2100px -2958px }
.pokesprite.steelix.shiny { background-position: -0px -3016px }
.pokesprite.steelix-mega.shiny { background-position: -70px -3016px }
.pokesprite.snubbull.shiny { background-position: -140px -3016px }
.pokesprite.granbull.shiny { background-position: -210px -3016px }
.pokesprite.qwilfish.shiny { background-position: -280px -3016px }
.pokesprite.scizor.shiny { background-position: -350px -3016px }
.pokesprite.scizor-mega.shiny { background-position: -420px -3016px }
.pokesprite.shuckle.shiny { background-position: -490px -3016px }
.pokesprite.heracross.shiny { background-position: -560px -3016px }
.pokesprite.heracross-mega.shiny { background-position: -630px -3016px }
.pokesprite.sneasel.shiny { background-position: -700px -3016px }
.pokesprite.teddiursa.shiny { background-position: -770px -3016px }
.pokesprite.ursaring.shiny { background-position: -840px -3016px }
.pokesprite.slugma.shiny { background-position: -910px -3016px }
.pokesprite.magcargo.shiny { background-position: -980px -3016px }
.pokesprite.swinub.shiny { background-position: -1050px -3016px }
.pokesprite.piloswine.shiny { background-position: -1120px -3016px }
.pokesprite.corsola.shiny { background-position: -1190px -3016px }
.pokesprite.corsola-galar.shiny { background-position: -1260px -3016px }
.pokesprite.remoraid.shiny { background-position: -1330px -3016px }
.pokesprite.octillery.shiny { background-position: -1400px -3016px }
.pokesprite.delibird.shiny { background-position: -1470px -3016px }
.pokesprite.mantine.shiny { background-position: -1540px -3016px }
.pokesprite.skarmory.shiny { background-position: -1610px -3016px }
.pokesprite.houndour.shiny { background-position: -1680px -3016px }
.pokesprite.houndoom.shiny { background-position: -1750px -3016px }
.pokesprite.houndoom-mega.shiny { background-position: -1820px -3016px }
.pokesprite.kingdra.shiny { background-position: -1890px -3016px }
.pokesprite.phanpy.shiny { background-position: -1960px -3016px }
.pokesprite.donphan.shiny { background-position: -2030px -3016px }
.pokesprite.porygon2.shiny { background-position: -2100px -3016px }
.pokesprite.stantler.shiny { background-position: -0px -3074px }
.pokesprite.smeargle.shiny { background-position: -70px -3074px }
.pokesprite.tyrogue.shiny { background-position: -140px -3074px }
.pokesprite.hitmontop.shiny { background-position: -210px -3074px }
.pokesprite.smoochum.shiny { background-position: -280px -3074px }
.pokesprite.elekid.shiny { background-position: -350px -3074px }
.pokesprite.magby.shiny { background-position: -420px -3074px }
.pokesprite.miltank.shiny { background-position: -490px -3074px }
.pokesprite.blissey.shiny { background-position: -560px -3074px }
.pokesprite.raikou.shiny { background-position: -630px -3074px }
.pokesprite.entei.shiny { background-position: -700px -3074px }
.pokesprite.suicune.shiny { background-position: -770px -3074px }
.pokesprite.larvitar.shiny { background-position: -840px -3074px }
.pokesprite.pupitar.shiny { background-position: -910px -3074px }
.pokesprite.tyranitar.shiny { background-position: -980px -3074px }
.pokesprite.tyranitar-mega.shiny { background-position: -1050px -3074px }
.pokesprite.lugia.shiny { background-position: -1120px -3074px }
.pokesprite.ho-oh.shiny { background-position: -1190px -3074px }
.pokesprite.celebi.shiny { background-position: -1260px -3074px }
.pokesprite.treecko.shiny { background-position: -1330px -3074px }
.pokesprite.grovyle.shiny { background-position: -1400px -3074px }
.pokesprite.sceptile.shiny { background-position: -1470px -3074px }
.pokesprite.sceptile-mega.shiny { background-position: -1540px -3074px }
.pokesprite.torchic.shiny { background-position: -1610px -3074px }
.pokesprite.combusken.shiny { background-position: -1680px -3074px }
.pokesprite.blaziken.shiny { background-position: -1750px -3074px }
.pokesprite.blaziken-mega.shiny { background-position: -1820px -3074px }
.pokesprite.mudkip.shiny { background-position: -1890px -3074px }
.pokesprite.marshtomp.shiny { background-position: -1960px -3074px }
.pokesprite.swampert.shiny { background-position: -2030px -3074px }
.pokesprite.swampert-mega.shiny { background-position: -2100px -3074px }
.pokesprite.poochyena.shiny { background-position: -0px -3132px }
.pokesprite.mightyena.shiny { background-position: -70px -3132px }
.pokesprite.zigzagoon.shiny { background-position: -140px -3132px }
.pokesprite.zigzagoon-galar.shiny { background-position: -210px -3132px }
.pokesprite.linoone.shiny { background-position: -280px -3132px }
.pokesprite.linoone-galar.shiny { background-position: -350px -3132px }
.pokesprite.wurmple.shiny { background-position: -420px -3132px }
.pokesprite.silcoon.shiny { background-position: -490px -3132px }
.pokesprite.beautifly.shiny { background-position: -560px -3132px }
.pokesprite.cascoon.shiny { background-position: -630px -3132px }
.pokesprite.dustox.shiny { background-position: -700px -3132px }
.pokesprite.lotad.shiny { background-position: -770px -3132px }
.pokesprite.lombre.shiny { background-position: -840px -3132px }
.pokesprite.ludicolo.shiny { background-position: -910px -3132px }
.pokesprite.seedot.shiny { background-position: -980px -3132px }
.pokesprite.nuzleaf.shiny { background-position: -1050px -3132px }
.pokesprite.shiftry.shiny { background-position: -1120px -3132px }
.pokesprite.taillow.shiny { background-position: -1190px -3132px }
.pokesprite.swellow.shiny { background-position: -1260px -3132px }
.pokesprite.wingull.shiny { background-position: -1330px -3132px }
.pokesprite.pelipper.shiny { background-position: -1400px -3132px }
.pokesprite.ralts.shiny { background-position: -1470px -3132px }
.pokesprite.kirlia.shiny { background-position: -1540px -3132px }
.pokesprite.gardevoir.shiny { background-position: -1610px -3132px }
.pokesprite.gardevoir-mega.shiny { background-position: -1680px -3132px }
.pokesprite.surskit.shiny { background-position: -1750px -3132px }
.pokesprite.masquerain.shiny { background-position: -1820px -3132px }
.pokesprite.shroomish.shiny { background-position: -1890px -3132px }
.pokesprite.breloom.shiny { background-position: -1960px -3132px }
.pokesprite.slakoth.shiny { background-position: -2030px -3132px }
.pokesprite.vigoroth.shiny { background-position: -2100px -3132px }
.pokesprite.slaking.shiny { background-position: -0px -3190px }
.pokesprite.nincada.shiny { background-position: -70px -3190px }
.pokesprite.ninjask.shiny { background-position: -140px -3190px }
.pokesprite.shedinja.shiny { background-position: -210px -3190px }
.pokesprite.whismur.shiny { background-position: -280px -3190px }
.pokesprite.loudred.shiny { background-position: -350px -3190px }
.pokesprite.exploud.shiny { background-position: -420px -3190px }
.pokesprite.makuhita.shiny { background-position: -490px -3190px }
.pokesprite.hariyama.shiny { background-position: -560px -3190px }
.pokesprite.azurill.shiny { background-position: -630px -3190px }
.pokesprite.nosepass.shiny { background-position: -700px -3190px }
.pokesprite.skitty.shiny { background-position: -770px -3190px }
.pokesprite.delcatty.shiny { background-position: -840px -3190px }
.pokesprite.sableye.shiny { background-position: -910px -3190px }
.pokesprite.sableye-mega.shiny { background-position: -980px -3190px }
.pokesprite.mawile.shiny { background-position: -1050px -3190px }
.pokesprite.mawile-mega.shiny { background-position: -1120px -3190px }
.pokesprite.aron.shiny { background-position: -1190px -3190px }
.pokesprite.lairon.shiny { background-position: -1260px -3190px }
.pokesprite.aggron.shiny { background-position: -1330px -3190px }
.pokesprite.aggron-mega.shiny { background-position: -1400px -3190px }
.pokesprite.meditite.shiny { background-position: -1470px -3190px }
.pokesprite.medicham.shiny { background-position: -1540px -3190px }
.pokesprite.medicham-mega.shiny { background-position: -1610px -3190px }
.pokesprite.electrike.shiny { background-position: -1680px -3190px }
.pokesprite.manectric.shiny { background-position: -1750px -3190px }
.pokesprite.manectric-mega.shiny { background-position: -1820px -3190px }
.pokesprite.plusle.shiny { background-position: -1890px -3190px }
.pokesprite.minun.shiny { background-position: -1960px -3190px }
.pokesprite.volbeat.shiny { background-position: -2030px -3190px }
.pokesprite.illumise.shiny { background-position: -2100px -3190px }
.pokesprite.roselia.shiny { background-position: -0px -3248px }
.pokesprite.gulpin.shiny { background-position: -70px -3248px }
.pokesprite.swalot.shiny { background-position: -140px -3248px }
.pokesprite.carvanha.shiny { background-position: -210px -3248px }
.pokesprite.sharpedo.shiny { background-position: -280px -3248px }
.pokesprite.sharpedo-mega.shiny { background-position: -350px -3248px }
.pokesprite.wailmer.shiny { background-position: -420px -3248px }
.pokesprite.wailord.shiny { background-position: -490px -3248px }
.pokesprite.numel.shiny { background-position: -560px -3248px }
.pokesprite.camerupt.shiny { background-position: -630px -3248px }
.pokesprite.camerupt-mega.shiny { background-position: -700px -3248px }
.pokesprite.torkoal.shiny { background-position: -770px -3248px }
.pokesprite.spoink.shiny { background-position: -840px -3248px }
.pokesprite.grumpig.shiny { background-position: -910px -3248px }
.pokesprite.spinda.shiny { background-position: -980px -3248px }
.pokesprite.spinda-blank.shiny { background-position: -1050px -3248px }
.pokesprite.spinda-filled.shiny { background-position: -1120px -3248px }
.pokesprite.trapinch.shiny { background-position: -1190px -3248px }
.pokesprite.vibrava.shiny { background-position: -1260px -3248px }
.pokesprite.flygon.shiny { background-position: -1330px -3248px }
.pokesprite.cacnea.shiny { background-position: -1400px -3248px }
.pokesprite.cacturne.shiny { background-position: -1470px -3248px }
.pokesprite.swablu.shiny { background-position: -1540px -3248px }
.pokesprite.altaria.shiny { background-position: -1610px -3248px }
.pokesprite.altaria-mega.shiny { background-position: -1680px -3248px }
.pokesprite.zangoose.shiny { background-position: -1750px -3248px }
.pokesprite.seviper.shiny { background-position: -1820px -3248px }
.pokesprite.lunatone.shiny { background-position: -1890px -3248px }
.pokesprite.solrock.shiny { background-position: -1960px -3248px }
.pokesprite.barboach.shiny { background-position: -2030px -3248px }
.pokesprite.whiscash.shiny { background-position: -2100px -3248px }
.pokesprite.corphish.shiny { background-position: -0px -3306px }
.pokesprite.crawdaunt.shiny { background-position: -70px -3306px }
.pokesprite.baltoy.shiny { background-position: -140px -3306px }
.pokesprite.claydol.shiny { background-position: -210px -3306px }
.pokesprite.lileep.shiny { background-position: -280px -3306px }
.pokesprite.cradily.shiny { background-position: -350px -3306px }
.pokesprite.anorith.shiny { background-position: -420px -3306px }
.pokesprite.armaldo.shiny { background-position: -490px -3306px }
.pokesprite.feebas.shiny { background-position: -560px -3306px }
.pokesprite.milotic.shiny { background-position: -630px -3306px }
.pokesprite.castform.shiny { background-position: -700px -3306px }
.pokesprite.castform-rainy.shiny { background-position: -770px -3306px }
.pokesprite.castform-snowy.shiny { background-position: -840px -3306px }
.pokesprite.castform-sunny.shiny { background-position: -910px -3306px }
.pokesprite.kecleon.shiny { background-position: -980px -3306px }
.pokesprite.shuppet.shiny { background-position: -1050px -3306px }
.pokesprite.banette.shiny { background-position: -1120px -3306px }
.pokesprite.banette-mega.shiny { background-position: -1190px -3306px }
.pokesprite.duskull.shiny { background-position: -1260px -3306px }
.pokesprite.dusclops.shiny { background-position: -1330px -3306px }
.pokesprite.tropius.shiny { background-position: -1400px -3306px }
.pokesprite.chimecho.shiny { background-position: -1470px -3306px }
.pokesprite.absol.shiny { background-position: -1540px -3306px }
.pokesprite.absol-mega.shiny { background-position: -1610px -3306px }
.pokesprite.wynaut.shiny { background-position: -1680px -3306px }
.pokesprite.snorunt.shiny { background-position: -1750px -3306px }
.pokesprite.glalie.shiny { background-position: -1820px -3306px }
.pokesprite.glalie-mega.shiny { background-position: -1890px -3306px }
.pokesprite.spheal.shiny { background-position: -1960px -3306px }
.pokesprite.sealeo.shiny { background-position: -2030px -3306px }
.pokesprite.walrein.shiny { background-position: -2100px -3306px }
.pokesprite.clamperl.shiny { background-position: -0px -3364px }
.pokesprite.huntail.shiny { background-position: -70px -3364px }
.pokesprite.gorebyss.shiny { background-position: -140px -3364px }
.pokesprite.relicanth.shiny { background-position: -210px -3364px }
.pokesprite.luvdisc.shiny { background-position: -280px -3364px }
.pokesprite.bagon.shiny { background-position: -350px -3364px }
.pokesprite.shelgon.shiny { background-position: -420px -3364px }
.pokesprite.salamence.shiny { background-position: -490px -3364px }
.pokesprite.salamence-mega.shiny { background-position: -560px -3364px }
.pokesprite.beldum.shiny { background-position: -630px -3364px }
.pokesprite.metang.shiny { background-position: -700px -3364px }
.pokesprite.metagross.shiny { background-position: -770px -3364px }
.pokesprite.metagross-mega.shiny { background-position: -840px -3364px }
.pokesprite.regirock.shiny { background-position: -910px -3364px }
.pokesprite.regice.shiny { background-position: -980px -3364px }
.pokesprite.registeel.shiny { background-position: -1050px -3364px }
.pokesprite.latias.shiny { background-position: -1120px -3364px }
.pokesprite.latias-mega.shiny { background-position: -1190px -3364px }
.pokesprite.latios.shiny { background-position: -1260px -3364px }
.pokesprite.latios-mega.shiny { background-position: -1330px -3364px }
.pokesprite.kyogre.shiny { background-position: -1400px -3364px }
.pokesprite.kyogre-primal.shiny { background-position: -1470px -3364px }
.pokesprite.groudon.shiny { background-position: -1540px -3364px }
.pokesprite.groudon-primal.shiny { background-position: -1610px -3364px }
.pokesprite.rayquaza.shiny { background-position: -1680px -3364px }
.pokesprite.rayquaza-mega.shiny { background-position: -1750px -3364px }
.pokesprite.jirachi.shiny { background-position: -1820px -3364px }
.pokesprite.deoxys.shiny { background-position: -1890px -3364px }
.pokesprite.deoxys-normal.shiny { background-position: -1890px -3364px }
.pokesprite.deoxys-attack.shiny { background-position: -1960px -3364px }
.pokesprite.deoxys-defense.shiny { background-position: -2030px -3364px }
.pokesprite.deoxys-speed.shiny { background-position: -2100px -3364px }
.pokesprite.turtwig.shiny { background-position: -0px -3422px }
.pokesprite.grotle.shiny { background-position: -70px -3422px }
.pokesprite.torterra.shiny { background-position: -140px -3422px }
.pokesprite.chimchar.shiny { background-position: -210px -3422px }
.pokesprite.monferno.shiny { background-position: -280px -3422px }
.pokesprite.infernape.shiny { background-position: -350px -3422px }
.pokesprite.piplup.shiny { background-position: -420px -3422px }
.pokesprite.prinplup.shiny { background-position: -490px -3422px }
.pokesprite.empoleon.shiny { background-position: -560px -3422px }
.pokesprite.starly.shiny { background-position: -630px -3422px }
.pokesprite.staravia.shiny { background-position: -700px -3422px }
.pokesprite.staraptor.shiny { background-position: -770px -3422px }
.pokesprite.bidoof.shiny { background-position: -840px -3422px }
.pokesprite.bibarel.shiny { background-position: -910px -3422px }
.pokesprite.kricketot.shiny { background-position: -980px -3422px }
.pokesprite.kricketune.shiny { background-position: -1050px -3422px }
.pokesprite.shinx.shiny { background-position: -1120px -3422px }
.pokesprite.luxio.shiny { background-position: -1190px -3422px }
.pokesprite.luxray.shiny { background-position: -1260px -3422px }
.pokesprite.budew.shiny { background-position: -1330px -3422px }
.pokesprite.roserade.shiny { background-position: -1400px -3422px }
.pokesprite.cranidos.shiny { background-position: -1470px -3422px }
.pokesprite.rampardos.shiny { background-position: -1540px -3422px }
.pokesprite.shieldon.shiny { background-position: -1610px -3422px }
.pokesprite.bastiodon.shiny { background-position: -1680px -3422px }
.pokesprite.burmy.shiny { background-position: -1750px -3422px }
.pokesprite.burmy-plant.shiny { background-position: -1750px -3422px }
.pokesprite.burmy-sandy.shiny { background-position: -1820px -3422px }
.pokesprite.burmy-trash.shiny { background-position: -1890px -3422px }
.pokesprite.wormadam.shiny { background-position: -1960px -3422px }
.pokesprite.wormadam-plant.shiny { background-position: -1960px -3422px }
.pokesprite.wormadam-sandy.shiny { background-position: -2030px -3422px }
.pokesprite.wormadam-trash.shiny { background-position: -2100px -3422px }
.pokesprite.mothim.shiny { background-position: -0px -3480px }
.pokesprite.mothim-plant.shiny { background-position: -0px -3480px }
.pokesprite.mothim-sandy.shiny { background-position: -0px -3480px }
.pokesprite.mothim-trash.shiny { background-position: -0px -3480px }
.pokesprite.combee.shiny { background-position: -70px -3480px }
.pokesprite.vespiquen.shiny { background-position: -140px -3480px }
.pokesprite.pachirisu.shiny { background-position: -210px -3480px }
.pokesprite.buizel.shiny { background-position: -280px -3480px }
.pokesprite.floatzel.shiny { background-position: -350px -3480px }
.pokesprite.cherubi.shiny { background-position: -420px -3480px }
.pokesprite.cherrim.shiny { background-position: -490px -3480px }
.pokesprite.cherrim-overcast.shiny { background-position: -490px -3480px }
.pokesprite.cherrim-sunshine.shiny { background-position: -560px -3480px }
.pokesprite.shellos.shiny { background-position: -630px -3480px }
.pokesprite.shellos-west.shiny { background-position: -630px -3480px }
.pokesprite.shellos-east.shiny { background-position: -700px -3480px }
.pokesprite.gastrodon.shiny { background-position: -770px -3480px }
.pokesprite.gastrodon-west.shiny { background-position: -770px -3480px }
.pokesprite.gastrodon-east.shiny { background-position: -840px -3480px }
.pokesprite.ambipom.shiny { background-position: -910px -3480px }
.pokesprite.drifloon.shiny { background-position: -980px -3480px }
.pokesprite.drifblim.shiny { background-position: -1050px -3480px }
.pokesprite.buneary.shiny { background-position: -1120px -3480px }
.pokesprite.lopunny.shiny { background-position: -1190px -3480px }
.pokesprite.lopunny-mega.shiny { background-position: -1260px -3480px }
.pokesprite.mismagius.shiny { background-position: -1330px -3480px }
.pokesprite.honchkrow.shiny { background-position: -1400px -3480px }
.pokesprite.glameow.shiny { background-position: -1470px -3480px }
.pokesprite.purugly.shiny { background-position: -1540px -3480px }
.pokesprite.chingling.shiny { background-position: -1610px -3480px }
.pokesprite.stunky.shiny { background-position: -1680px -3480px }
.pokesprite.skuntank.shiny { background-position: -1750px -3480px }
.pokesprite.bronzor.shiny { background-position: -1820px -3480px }
.pokesprite.bronzong.shiny { background-position: -1890px -3480px }
.pokesprite.bonsly.shiny { background-position: -1960px -3480px }
.pokesprite.mime-jr.shiny { background-position: -2030px -3480px }
.pokesprite.happiny.shiny { background-position: -2100px -3480px }
.pokesprite.chatot.shiny { background-position: -0px -3538px }
.pokesprite.spiritomb.shiny { background-position: -70px -3538px }
.pokesprite.gible.shiny { background-position: -140px -3538px }
.pokesprite.gabite.shiny { background-position: -210px -3538px }
.pokesprite.garchomp.shiny { background-position: -280px -3538px }
.pokesprite.garchomp-mega.shiny { background-position: -350px -3538px }
.pokesprite.munchlax.shiny { background-position: -420px -3538px }
.pokesprite.riolu.shiny { background-position: -490px -3538px }
.pokesprite.lucario.shiny { background-position: -560px -3538px }
.pokesprite.lucario-mega.shiny { background-position: -630px -3538px }
.pokesprite.hippopotas.shiny { background-position: -700px -3538px }
.pokesprite.hippopotas.shiny.female { background-position: -770px -3538px }
.pokesprite.hippowdon.shiny { background-position: -840px -3538px }
.pokesprite.hippowdon.shiny.female { background-position: -910px -3538px }
.pokesprite.skorupi.shiny { background-position: -980px -3538px }
.pokesprite.drapion.shiny { background-position: -1050px -3538px }
.pokesprite.croagunk.shiny { background-position: -1120px -3538px }
.pokesprite.toxicroak.shiny { background-position: -1190px -3538px }
.pokesprite.carnivine.shiny { background-position: -1260px -3538px }
.pokesprite.finneon.shiny { background-position: -1330px -3538px }
.pokesprite.lumineon.shiny { background-position: -1400px -3538px }
.pokesprite.mantyke.shiny { background-position: -1470px -3538px }
.pokesprite.snover.shiny { background-position: -1540px -3538px }
.pokesprite.abomasnow.shiny { background-position: -1610px -3538px }
.pokesprite.abomasnow-mega.shiny { background-position: -1680px -3538px }
.pokesprite.weavile.shiny { background-position: -1750px -3538px }
.pokesprite.magnezone.shiny { background-position: -1820px -3538px }
.pokesprite.lickilicky.shiny { background-position: -1890px -3538px }
.pokesprite.rhyperior.shiny { background-position: -1960px -3538px }
.pokesprite.tangrowth.shiny { background-position: -2030px -3538px }
.pokesprite.electivire.shiny { background-position: -2100px -3538px }
.pokesprite.magmortar.shiny { background-position: -0px -3596px }
.pokesprite.togekiss.shiny { background-position: -70px -3596px }
.pokesprite.yanmega.shiny { background-position: -140px -3596px }
.pokesprite.leafeon.shiny { background-position: -210px -3596px }
.pokesprite.glaceon.shiny { background-position: -280px -3596px }
.pokesprite.gliscor.shiny { background-position: -350px -3596px }
.pokesprite.mamoswine.shiny { background-position: -420px -3596px }
.pokesprite.porygon-z.shiny { background-position: -490px -3596px }
.pokesprite.gallade.shiny { background-position: -560px -3596px }
.pokesprite.gallade-mega.shiny { background-position: -630px -3596px }
.pokesprite.probopass.shiny { background-position: -700px -3596px }
.pokesprite.dusknoir.shiny { background-position: -770px -3596px }
.pokesprite.froslass.shiny { background-position: -840px -3596px }
.pokesprite.rotom.shiny { background-position: -910px -3596px }
.pokesprite.rotom-fan.shiny { background-position: -980px -3596px }
.pokesprite.rotom-frost.shiny { background-position: -1050px -3596px }
.pokesprite.rotom-heat.shiny { background-position: -1120px -3596px }
.pokesprite.rotom-mow.shiny { background-position: -1190px -3596px }
.pokesprite.rotom-wash.shiny { background-position: -1260px -3596px }
.pokesprite.uxie.shiny { background-position: -1330px -3596px }
.pokesprite.mesprit.shiny { background-position: -1400px -3596px }
.pokesprite.azelf.shiny { background-position: -1470px -3596px }
.pokesprite.dialga.shiny { background-position: -1540px -3596px }
.pokesprite.palkia.shiny { background-position: -1610px -3596px }
.pokesprite.heatran.shiny { background-position: -1680px -3596px }
.pokesprite.regigigas.shiny { background-position: -1750px -3596px }
.pokesprite.giratina.shiny { background-position: -1820px -3596px }
.pokesprite.giratina-altered.shiny { background-position: -1820px -3596px }
.pokesprite.giratina-origin.shiny { background-position: -1890px -3596px }
.pokesprite.cresselia.shiny { background-position: -1960px -3596px }
.pokesprite.phione.shiny { background-position: -2030px -3596px }
.pokesprite.manaphy.shiny { background-position: -2100px -3596px }
.pokesprite.darkrai.shiny { background-position: -0px -3654px }
.pokesprite.shaymin.shiny { background-position: -70px -3654px }
.pokesprite.shaymin-land.shiny { background-position: -70px -3654px }
.pokesprite.shaymin-sky.shiny { background-position: -140px -3654px }
.pokesprite.arceus.shiny { background-position: -210px -3654px }
.pokesprite.arceus-normal.shiny { background-position: -210px -3654px }
.pokesprite.arceus-unknown.shiny { background-position: -210px -3654px }
.pokesprite.arceus-bug.shiny { background-position: -280px -3654px }
.pokesprite.arceus-dark.shiny { background-position: -350px -3654px }
.pokesprite.arceus-dragon.shiny { background-position: -420px -3654px }
.pokesprite.arceus-electric.shiny { background-position: -490px -3654px }
.pokesprite.arceus-fairy.shiny { background-position: -560px -3654px }
.pokesprite.arceus-fighting.shiny { background-position: -630px -3654px }
.pokesprite.arceus-fire.shiny { background-position: -700px -3654px }
.pokesprite.arceus-flying.shiny { background-position: -770px -3654px }
.pokesprite.arceus-ghost.shiny { background-position: -840px -3654px }
.pokesprite.arceus-grass.shiny { background-position: -910px -3654px }
.pokesprite.arceus-ground.shiny { background-position: -980px -3654px }
.pokesprite.arceus-ice.shiny { background-position: -1050px -3654px }
.pokesprite.arceus-poison.shiny { background-position: -1120px -3654px }
.pokesprite.arceus-psychic.shiny { background-position: -1190px -3654px }
.pokesprite.arceus-rock.shiny { background-position: -1260px -3654px }
.pokesprite.arceus-steel.shiny { background-position: -1330px -3654px }
.pokesprite.arceus-water.shiny { background-position: -1400px -3654px }
.pokesprite.victini.shiny { background-position: -1470px -3654px }
.pokesprite.snivy.shiny { background-position: -1540px -3654px }
.pokesprite.servine.shiny { background-position: -1610px -3654px }
.pokesprite.serperior.shiny { background-position: -1680px -3654px }
.pokesprite.tepig.shiny { background-position: -1750px -3654px }
.pokesprite.pignite.shiny { background-position: -1820px -3654px }
.pokesprite.emboar.shiny { background-position: -1890px -3654px }
.pokesprite.oshawott.shiny { background-position: -1960px -3654px }
.pokesprite.dewott.shiny { background-position: -2030px -3654px }
.pokesprite.samurott.shiny { background-position: -2100px -3654px }
.pokesprite.patrat.shiny { background-position: -0px -3712px }
.pokesprite.watchog.shiny { background-position: -70px -3712px }
.pokesprite.lillipup.shiny { background-position: -140px -3712px }
.pokesprite.herdier.shiny { background-position: -210px -3712px }
.pokesprite.stoutland.shiny { background-position: -280px -3712px }
.pokesprite.purrloin.shiny { background-position: -350px -3712px }
.pokesprite.liepard.shiny { background-position: -420px -3712px }
.pokesprite.pansage.shiny { background-position: -490px -3712px }
.pokesprite.simisage.shiny { background-position: -560px -3712px }
.pokesprite.pansear.shiny { background-position: -630px -3712px }
.pokesprite.simisear.shiny { background-position: -700px -3712px }
.pokesprite.panpour.shiny { background-position: -770px -3712px }
.pokesprite.simipour.shiny { background-position: -840px -3712px }
.pokesprite.munna.shiny { background-position: -910px -3712px }
.pokesprite.musharna.shiny { background-position: -980px -3712px }
.pokesprite.pidove.shiny { background-position: -1050px -3712px }
.pokesprite.tranquill.shiny { background-position: -1120px -3712px }
.pokesprite.unfezant.shiny { background-position: -1190px -3712px }
.pokesprite.unfezant.shiny.female { background-position: -1260px -3712px }
.pokesprite.blitzle.shiny { background-position: -1330px -3712px }
.pokesprite.zebstrika.shiny { background-position: -1400px -3712px }
.pokesprite.roggenrola.shiny { background-position: -1470px -3712px }
.pokesprite.boldore.shiny { background-position: -1540px -3712px }
.pokesprite.gigalith.shiny { background-position: -1610px -3712px }
.pokesprite.woobat.shiny { background-position: -1680px -3712px }
.pokesprite.swoobat.shiny { background-position: -1750px -3712px }
.pokesprite.drilbur.shiny { background-position: -1820px -3712px }
.pokesprite.excadrill.shiny { background-position: -1890px -3712px }
.pokesprite.audino.shiny { background-position: -1960px -3712px }
.pokesprite.audino-mega.shiny { background-position: -2030px -3712px }
.pokesprite.timburr.shiny { background-position: -2100px -3712px }
.pokesprite.gurdurr.shiny { background-position: -0px -3770px }
.pokesprite.conkeldurr.shiny { background-position: -70px -3770px }
.pokesprite.tympole.shiny { background-position: -140px -3770px }
.pokesprite.palpitoad.shiny { background-position: -210px -3770px }
.pokesprite.seismitoad.shiny { background-position: -280px -3770px }
.pokesprite.throh.shiny { background-position: -350px -3770px }
.pokesprite.sawk.shiny { background-position: -420px -3770px }
.pokesprite.sewaddle.shiny { background-position: -490px -3770px }
.pokesprite.swadloon.shiny { background-position: -560px -3770px }
.pokesprite.leavanny.shiny { background-position: -630px -3770px }
.pokesprite.venipede.shiny { background-position: -700px -3770px }
.pokesprite.whirlipede.shiny { background-position: -770px -3770px }
.pokesprite.scolipede.shiny { background-position: -840px -3770px }
.pokesprite.cottonee.shiny { background-position: -910px -3770px }
.pokesprite.whimsicott.shiny { background-position: -980px -3770px }
.pokesprite.petilil.shiny { background-position: -1050px -3770px }
.pokesprite.lilligant.shiny { background-position: -1120px -3770px }
.pokesprite.basculin.shiny { background-position: -1190px -3770px }
.pokesprite.basculin-red-striped.shiny { background-position: -1190px -3770px }
.pokesprite.basculin-blue-striped.shiny { background-position: -1260px -3770px }
.pokesprite.sandile.shiny { background-position: -1330px -3770px }
.pokesprite.krokorok.shiny { background-position: -1400px -3770px }
.pokesprite.krookodile.shiny { background-position: -1470px -3770px }
.pokesprite.darumaka.shiny { background-position: -1540px -3770px }
.pokesprite.darumaka-galar.shiny { background-position: -1610px -3770px }
.pokesprite.darmanitan.shiny { background-position: -1680px -3770px }
.pokesprite.darmanitan-standard.shiny { background-position: -1680px -3770px }
.pokesprite.darmanitan-galar.shiny { background-position: -1750px -3770px }
.pokesprite.darmanitan-galar-zen.shiny { background-position: -1820px -3770px }
.pokesprite.darmanitan-zen.shiny { background-position: -1890px -3770px }
.pokesprite.maractus.shiny { background-position: -1960px -3770px }
.pokesprite.dwebble.shiny { background-position: -2030px -3770px }
.pokesprite.crustle.shiny { background-position: -2100px -3770px }
.pokesprite.scraggy.shiny { background-position: -0px -3828px }
.pokesprite.scrafty.shiny { background-position: -70px -3828px }
.pokesprite.sigilyph.shiny { background-position: -140px -3828px }
.pokesprite.yamask.shiny { background-position: -210px -3828px }
.pokesprite.yamask-galar.shiny { background-position: -280px -3828px }
.pokesprite.cofagrigus.shiny { background-position: -350px -3828px }
.pokesprite.tirtouga.shiny { background-position: -420px -3828px }
.pokesprite.carracosta.shiny { background-position: -490px -3828px }
.pokesprite.archen.shiny { background-position: -560px -3828px }
.pokesprite.archeops.shiny { background-position: -630px -3828px }
.pokesprite.trubbish.shiny { background-position: -700px -3828px }
.pokesprite.garbodor.shiny { background-position: -770px -3828px }
.pokesprite.garbodor-gmax.shiny { background-position: -840px -3828px }
.pokesprite.zorua.shiny { background-position: -910px -3828px }
.pokesprite.zoroark.shiny { background-position: -980px -3828px }
.pokesprite.minccino.shiny { background-position: -1050px -3828px }
.pokesprite.cinccino.shiny { background-position: -1120px -3828px }
.pokesprite.gothita.shiny { background-position: -1190px -3828px }
.pokesprite.gothorita.shiny { background-position: -1260px -3828px }
.pokesprite.gothitelle.shiny { background-position: -1330px -3828px }
.pokesprite.solosis.shiny { background-position: -1400px -3828px }
.pokesprite.duosion.shiny { background-position: -1470px -3828px }
.pokesprite.reuniclus.shiny { background-position: -1540px -3828px }
.pokesprite.ducklett.shiny { background-position: -1610px -3828px }
.pokesprite.swanna.shiny { background-position: -1680px -3828px }
.pokesprite.vanillite.shiny { background-position: -1750px -3828px }
.pokesprite.vanillish.shiny { background-position: -1820px -3828px }
.pokesprite.vanilluxe.shiny { background-position: -1890px -3828px }
.pokesprite.deerling.shiny { background-position: -1960px -3828px }
.pokesprite.deerling-spring.shiny { background-position: -1960px -3828px }
.pokesprite.deerling-autumn.shiny { background-position: -2030px -3828px }
.pokesprite.deerling-summer.shiny { background-position: -2100px -3828px }
.pokesprite.deerling-winter.shiny { background-position: -0px -3886px }
.pokesprite.sawsbuck.shiny { background-position: -70px -3886px }
.pokesprite.sawsbuck-spring.shiny { background-position: -70px -3886px }
.pokesprite.sawsbuck-autumn.shiny { background-position: -140px -3886px }
.pokesprite.sawsbuck-summer.shiny { background-position: -210px -3886px }
.pokesprite.sawsbuck-winter.shiny { background-position: -280px -3886px }
.pokesprite.emolga.shiny { background-position: -350px -3886px }
.pokesprite.karrablast.shiny { background-position: -420px -3886px }
.pokesprite.escavalier.shiny { background-position: -490px -3886px }
.pokesprite.foongus.shiny { background-position: -560px -3886px }
.pokesprite.amoonguss.shiny { background-position: -630px -3886px }
.pokesprite.frillish.shiny { background-position: -700px -3886px }
.pokesprite.frillish.shiny.female { background-position: -770px -3886px }
.pokesprite.jellicent.shiny { background-position: -840px -3886px }
.pokesprite.jellicent.shiny.female { background-position: -910px -3886px }
.pokesprite.alomomola.shiny { background-position: -980px -3886px }
.pokesprite.joltik.shiny { background-position: -1050px -3886px }
.pokesprite.galvantula.shiny { background-position: -1120px -3886px }
.pokesprite.ferroseed.shiny { background-position: -1190px -3886px }
.pokesprite.ferrothorn.shiny { background-position: -1260px -3886px }
.pokesprite.klink.shiny { background-position: -1330px -3886px }
.pokesprite.klang.shiny { background-position: -1400px -3886px }
.pokesprite.klinklang.shiny { background-position: -1470px -3886px }
.pokesprite.tynamo.shiny { background-position: -1540px -3886px }
.pokesprite.eelektrik.shiny { background-position: -1610px -3886px }
.pokesprite.eelektross.shiny { background-position: -1680px -3886px }
.pokesprite.elgyem.shiny { background-position: -1750px -3886px }
.pokesprite.beheeyem.shiny { background-position: -1820px -3886px }
.pokesprite.litwick.shiny { background-position: -1890px -3886px }
.pokesprite.lampent.shiny { background-position: -1960px -3886px }
.pokesprite.chandelure.shiny { background-position: -2030px -3886px }
.pokesprite.axew.shiny { background-position: -2100px -3886px }
.pokesprite.fraxure.shiny { background-position: -0px -3944px }
.pokesprite.haxorus.shiny { background-position: -70px -3944px }
.pokesprite.cubchoo.shiny { background-position: -140px -3944px }
.pokesprite.beartic.shiny { background-position: -210px -3944px }
.pokesprite.cryogonal.shiny { background-position: -280px -3944px }
.pokesprite.shelmet.shiny { background-position: -350px -3944px }
.pokesprite.accelgor.shiny { background-position: -420px -3944px }
.pokesprite.stunfisk.shiny { background-position: -490px -3944px }
.pokesprite.stunfisk-galar.shiny { background-position: -560px -3944px }
.pokesprite.mienfoo.shiny { background-position: -630px -3944px }
.pokesprite.mienshao.shiny { background-position: -700px -3944px }
.pokesprite.druddigon.shiny { background-position: -770px -3944px }
.pokesprite.golett.shiny { background-position: -840px -3944px }
.pokesprite.golurk.shiny { background-position: -910px -3944px }
.pokesprite.pawniard.shiny { background-position: -980px -3944px }
.pokesprite.bisharp.shiny { background-position: -1050px -3944px }
.pokesprite.bouffalant.shiny { background-position: -1120px -3944px }
.pokesprite.rufflet.shiny { background-position: -1190px -3944px }
.pokesprite.braviary.shiny { background-position: -1260px -3944px }
.pokesprite.vullaby.shiny { background-position: -1330px -3944px }
.pokesprite.mandibuzz.shiny { background-position: -1400px -3944px }
.pokesprite.heatmor.shiny { background-position: -1470px -3944px }
.pokesprite.durant.shiny { background-position: -1540px -3944px }
.pokesprite.deino.shiny { background-position: -1610px -3944px }
.pokesprite.zweilous.shiny { background-position: -1680px -3944px }
.pokesprite.hydreigon.shiny { background-position: -1750px -3944px }
.pokesprite.larvesta.shiny { background-position: -1820px -3944px }
.pokesprite.volcarona.shiny { background-position: -1890px -3944px }
.pokesprite.cobalion.shiny { background-position: -1960px -3944px }
.pokesprite.terrakion.shiny { background-position: -2030px -3944px }
.pokesprite.virizion.shiny { background-position: -2100px -3944px }
.pokesprite.tornadus.shiny { background-position: -0px -4002px }
.pokesprite.tornadus-incarnate.shiny { background-position: -0px -4002px }
.pokesprite.tornadus-therian.shiny { background-position: -70px -4002px }
.pokesprite.thundurus.shiny { background-position: -140px -4002px }
.pokesprite.thundurus-incarnate.shiny { background-position: -140px -4002px }
.pokesprite.thundurus-therian.shiny { background-position: -210px -4002px }
.pokesprite.reshiram.shiny { background-position: -280px -4002px }
.pokesprite.zekrom.shiny { background-position: -350px -4002px }
.pokesprite.landorus.shiny { background-position: -420px -4002px }
.pokesprite.landorus-incarnate.shiny { background-position: -420px -4002px }
.pokesprite.landorus-therian.shiny { background-position: -490px -4002px }
.pokesprite.kyurem.shiny { background-position: -560px -4002px }
.pokesprite.kyurem-black.shiny { background-position: -630px -4002px }
.pokesprite.kyurem-white.shiny { background-position: -700px -4002px }
.pokesprite.keldeo.shiny { background-position: -770px -4002px }
.pokesprite.keldeo-ordinary.shiny { background-position: -770px -4002px }
.pokesprite.keldeo-resolute.shiny { background-position: -840px -4002px }
.pokesprite.meloetta.shiny { background-position: -910px -4002px }
.pokesprite.meloetta-aria.shiny { background-position: -910px -4002px }
.pokesprite.meloetta-pirouette.shiny { background-position: -980px -4002px }
.pokesprite.genesect.shiny { background-position: -1050px -4002px }
.pokesprite.genesect-burn.shiny { background-position: -1120px -4002px }
.pokesprite.genesect-chill.shiny { background-position: -1190px -4002px }
.pokesprite.genesect-douse.shiny { background-position: -1260px -4002px }
.pokesprite.genesect-shock.shiny { background-position: -1330px -4002px }
.pokesprite.genesect-standard.shiny { background-position: -1400px -4002px }
.pokesprite.chespin.shiny { background-position: -1470px -4002px }
.pokesprite.quilladin.shiny { background-position: -1540px -4002px }
.pokesprite.chesnaught.shiny { background-position: -1610px -4002px }
.pokesprite.fennekin.shiny { background-position: -1680px -4002px }
.pokesprite.braixen.shiny { background-position: -1750px -4002px }
.pokesprite.delphox.shiny { background-position: -1820px -4002px }
.pokesprite.froakie.shiny { background-position: -1890px -4002px }
.pokesprite.frogadier.shiny { background-position: -1960px -4002px }
.pokesprite.greninja.shiny { background-position: -2030px -4002px }
.pokesprite.greninja-ash.shiny { background-position: -2100px -4002px }
.pokesprite.greninja-battle-bond.shiny { background-position: -2100px -4002px }
.pokesprite.bunnelby.shiny { background-position: -0px -4060px }
.pokesprite.diggersby.shiny { background-position: -70px -4060px }
.pokesprite.fletchling.shiny { background-position: -140px -4060px }
.pokesprite.fletchinder.shiny { background-position: -210px -4060px }
.pokesprite.talonflame.shiny { background-position: -280px -4060px }
.pokesprite.scatterbug.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-archipelago.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-continental.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-elegant.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-fancy.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-garden.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-high-plains.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-icy-snow.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-jungle.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-marine.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-meadow.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-modern.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-monsoon.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-ocean.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-poke-ball.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-polar.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-river.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-sandstorm.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-savanna.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-sun.shiny { background-position: -350px -4060px }
.pokesprite.scatterbug-tundra.shiny { background-position: -350px -4060px }
.pokesprite.spewpa.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-archipelago.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-continental.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-elegant.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-fancy.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-garden.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-high-plains.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-icy-snow.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-jungle.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-marine.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-meadow.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-modern.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-monsoon.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-ocean.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-poke-ball.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-polar.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-river.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-sandstorm.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-savanna.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-sun.shiny { background-position: -420px -4060px }
.pokesprite.spewpa-tundra.shiny { background-position: -420px -4060px }
.pokesprite.vivillon.shiny { background-position: -490px -4060px }
.pokesprite.vivillon-meadow.shiny { background-position: -490px -4060px }
.pokesprite.vivillon-archipelago.shiny { background-position: -560px -4060px }
.pokesprite.vivillon-continental.shiny { background-position: -630px -4060px }
.pokesprite.vivillon-elegant.shiny { background-position: -700px -4060px }
.pokesprite.vivillon-fancy.shiny { background-position: -770px -4060px }
.pokesprite.vivillon-garden.shiny { background-position: -840px -4060px }
.pokesprite.vivillon-high-plains.shiny { background-position: -910px -4060px }
.pokesprite.vivillon-icy-snow.shiny { background-position: -980px -4060px }
.pokesprite.vivillon-jungle.shiny { background-position: -1050px -4060px }
.pokesprite.vivillon-marine.shiny { background-position: -1120px -4060px }
.pokesprite.vivillon-modern.shiny { background-position: -1190px -4060px }
.pokesprite.vivillon-monsoon.shiny { background-position: -1260px -4060px }
.pokesprite.vivillon-ocean.shiny { background-position: -1330px -4060px }
.pokesprite.vivillon-poke-ball.shiny { background-position: -1400px -4060px }
.pokesprite.vivillon-polar.shiny { background-position: -1470px -4060px }
.pokesprite.vivillon-river.shiny { background-position: -1540px -4060px }
.pokesprite.vivillon-sandstorm.shiny { background-position: -1610px -4060px }
.pokesprite.vivillon-savanna.shiny { background-position: -1680px -4060px }
.pokesprite.vivillon-sun.shiny { background-position: -1750px -4060px }
.pokesprite.vivillon-tundra.shiny { background-position: -1820px -4060px }
.pokesprite.litleo.shiny { background-position: -1890px -4060px }
.pokesprite.pyroar.shiny { background-position: -1960px -4060px }
.pokesprite.pyroar.shiny.female { background-position: -2030px -4060px }
.pokesprite.flabebe.shiny { background-position: -2100px -4060px }
.pokesprite.flabebe-red.shiny { background-position: -2100px -4060px }
.pokesprite.flabebe-blue.shiny { background-position: -0px -4118px }
.pokesprite.flabebe-orange.shiny { background-position: -70px -4118px }
.pokesprite.flabebe-white.shiny { background-position: -140px -4118px }
.pokesprite.flabebe-yellow.shiny { background-position: -210px -4118px }
.pokesprite.floette.shiny { background-position: -280px -4118px }
.pokesprite.floette-red.shiny { background-position: -280px -4118px }
.pokesprite.floette-blue.shiny { background-position: -350px -4118px }
.pokesprite.floette-eternal.shiny { background-position: -420px -4118px }
.pokesprite.floette-orange.shiny { background-position: -490px -4118px }
.pokesprite.floette-white.shiny { background-position: -560px -4118px }
.pokesprite.floette-yellow.shiny { background-position: -630px -4118px }
.pokesprite.florges.shiny { background-position: -700px -4118px }
.pokesprite.florges-red.shiny { background-position: -700px -4118px }
.pokesprite.florges-blue.shiny { background-position: -770px -4118px }
.pokesprite.florges-orange.shiny { background-position: -840px -4118px }
.pokesprite.florges-white.shiny { background-position: -910px -4118px }
.pokesprite.florges-yellow.shiny { background-position: -980px -4118px }
.pokesprite.skiddo.shiny { background-position: -1050px -4118px }
.pokesprite.gogoat.shiny { background-position: -1120px -4118px }
.pokesprite.pancham.shiny { background-position: -1190px -4118px }
.pokesprite.pangoro.shiny { background-position: -1260px -4118px }
.pokesprite.furfrou.shiny { background-position: -1330px -4118px }
.pokesprite.furfrou-natural.shiny { background-position: -1330px -4118px }
.pokesprite.furfrou-dandy.shiny { background-position: -1400px -4118px }
.pokesprite.furfrou-debutante.shiny { background-position: -1470px -4118px }
.pokesprite.furfrou-diamond.shiny { background-position: -1540px -4118px }
.pokesprite.furfrou-heart.shiny { background-position: -1610px -4118px }
.pokesprite.furfrou-kabuki.shiny { background-position: -1680px -4118px }
.pokesprite.furfrou-la-reine.shiny { background-position: -1750px -4118px }
.pokesprite.furfrou-matron.shiny { background-position: -1820px -4118px }
.pokesprite.furfrou-pharaoh.shiny { background-position: -1890px -4118px }
.pokesprite.furfrou-star.shiny { background-position: -1960px -4118px }
.pokesprite.espurr.shiny { background-position: -2030px -4118px }
.pokesprite.meowstic.shiny { background-position: -2100px -4118px }
.pokesprite.meowstic.shiny.female { background-position: -0px -4176px }
.pokesprite.honedge.shiny { background-position: -70px -4176px }
.pokesprite.doublade.shiny { background-position: -140px -4176px }
.pokesprite.aegislash.shiny { background-position: -210px -4176px }
.pokesprite.aegislash-shield.shiny { background-position: -210px -4176px }
.pokesprite.aegislash-blade.shiny { background-position: -280px -4176px }
.pokesprite.spritzee.shiny { background-position: -350px -4176px }
.pokesprite.aromatisse.shiny { background-position: -420px -4176px }
.pokesprite.swirlix.shiny { background-position: -490px -4176px }
.pokesprite.slurpuff.shiny { background-position: -560px -4176px }
.pokesprite.inkay.shiny { background-position: -630px -4176px }
.pokesprite.malamar.shiny { background-position: -700px -4176px }
.pokesprite.binacle.shiny { background-position: -770px -4176px }
.pokesprite.barbaracle.shiny { background-position: -840px -4176px }
.pokesprite.skrelp.shiny { background-position: -910px -4176px }
.pokesprite.dragalge.shiny { background-position: -980px -4176px }
.pokesprite.clauncher.shiny { background-position: -1050px -4176px }
.pokesprite.clawitzer.shiny { background-position: -1120px -4176px }
.pokesprite.helioptile.shiny { background-position: -1190px -4176px }
.pokesprite.heliolisk.shiny { background-position: -1260px -4176px }
.pokesprite.tyrunt.shiny { background-position: -1330px -4176px }
.pokesprite.tyrantrum.shiny { background-position: -1400px -4176px }
.pokesprite.amaura.shiny { background-position: -1470px -4176px }
.pokesprite.aurorus.shiny { background-position: -1540px -4176px }
.pokesprite.sylveon.shiny { background-position: -1610px -4176px }
.pokesprite.hawlucha.shiny { background-position: -1680px -4176px }
.pokesprite.dedenne.shiny { background-position: -1750px -4176px }
.pokesprite.carbink.shiny { background-position: -1820px -4176px }
.pokesprite.goomy.shiny { background-position: -1890px -4176px }
.pokesprite.sliggoo.shiny { background-position: -1960px -4176px }
.pokesprite.goodra.shiny { background-position: -2030px -4176px }
.pokesprite.klefki.shiny { background-position: -2100px -4176px }
.pokesprite.phantump.shiny { background-position: -0px -4234px }
.pokesprite.trevenant.shiny { background-position: -70px -4234px }
.pokesprite.pumpkaboo.shiny { background-position: -140px -4234px }
.pokesprite.pumpkaboo-average.shiny { background-position: -140px -4234px }
.pokesprite.pumpkaboo-large.shiny { background-position: -210px -4234px }
.pokesprite.pumpkaboo-small.shiny { background-position: -280px -4234px }
.pokesprite.pumpkaboo-super.shiny { background-position: -350px -4234px }
.pokesprite.gourgeist.shiny { background-position: -420px -4234px }
.pokesprite.gourgeist-average.shiny { background-position: -420px -4234px }
.pokesprite.gourgeist-large.shiny { background-position: -420px -4234px }
.pokesprite.gourgeist-small.shiny { background-position: -420px -4234px }
.pokesprite.gourgeist-super.shiny { background-position: -420px -4234px }
.pokesprite.bergmite.shiny { background-position: -490px -4234px }
.pokesprite.avalugg.shiny { background-position: -560px -4234px }
.pokesprite.noibat.shiny { background-position: -630px -4234px }
.pokesprite.noivern.shiny { background-position: -700px -4234px }
.pokesprite.xerneas.shiny { background-position: -770px -4234px }
.pokesprite.xerneas-neutral.shiny { background-position: -770px -4234px }
.pokesprite.xerneas-active.shiny { background-position: -840px -4234px }
.pokesprite.yveltal.shiny { background-position: -910px -4234px }
.pokesprite.zygarde-10.shiny { background-position: -980px -4234px }
.pokesprite.zygarde.shiny { background-position: -1050px -4234px }
.pokesprite.zygarde-50.shiny { background-position: -1050px -4234px }
.pokesprite.zygarde-complete.shiny { background-position: -1120px -4234px }
.pokesprite.diancie.shiny { background-position: -1190px -4234px }
.pokesprite.diancie-mega.shiny { background-position: -1260px -4234px }
.pokesprite.hoopa.shiny { background-position: -1330px -4234px }
.pokesprite.hoopa-unbound.shiny { background-position: -1400px -4234px }
.pokesprite.volcanion.shiny { background-position: -1470px -4234px }
.pokesprite.rowlet.shiny { background-position: -1540px -4234px }
.pokesprite.dartrix.shiny { background-position: -1610px -4234px }
.pokesprite.decidueye.shiny { background-position: -1680px -4234px }
.pokesprite.litten.shiny { background-position: -1750px -4234px }
.pokesprite.torracat.shiny { background-position: -1820px -4234px }
.pokesprite.incineroar.shiny { background-position: -1890px -4234px }
.pokesprite.popplio.shiny { background-position: -1960px -4234px }
.pokesprite.brionne.shiny { background-position: -2030px -4234px }
.pokesprite.primarina.shiny { background-position: -2100px -4234px }
.pokesprite.pikipek.shiny { background-position: -0px -4292px }
.pokesprite.trumbeak.shiny { background-position: -70px -4292px }
.pokesprite.toucannon.shiny { background-position: -140px -4292px }
.pokesprite.yungoos.shiny { background-position: -210px -4292px }
.pokesprite.gumshoos.shiny { background-position: -280px -4292px }
.pokesprite.gumshoos-totem.shiny { background-position: -280px -4292px }
.pokesprite.grubbin.shiny { background-position: -350px -4292px }
.pokesprite.charjabug.shiny { background-position: -420px -4292px }
.pokesprite.vikavolt.shiny { background-position: -490px -4292px }
.pokesprite.vikavolt-totem.shiny { background-position: -490px -4292px }
.pokesprite.crabrawler.shiny { background-position: -560px -4292px }
.pokesprite.crabominable.shiny { background-position: -630px -4292px }
.pokesprite.oricorio.shiny { background-position: -700px -4292px }
.pokesprite.oricorio-baile.shiny { background-position: -700px -4292px }
.pokesprite.oricorio-pau.shiny { background-position: -770px -4292px }
.pokesprite.oricorio-pom-pom.shiny { background-position: -840px -4292px }
.pokesprite.oricorio-sensu.shiny { background-position: -910px -4292px }
.pokesprite.cutiefly.shiny { background-position: -980px -4292px }
.pokesprite.ribombee.shiny { background-position: -1050px -4292px }
.pokesprite.ribombee-totem.shiny { background-position: -1050px -4292px }
.pokesprite.rockruff.shiny { background-position: -1120px -4292px }
.pokesprite.rockruff-own-tempo.shiny { background-position: -1120px -4292px }
.pokesprite.lycanroc.shiny { background-position: -1190px -4292px }
.pokesprite.lycanroc-midday.shiny { background-position: -1190px -4292px }
.pokesprite.lycanroc-dusk.shiny { background-position: -1260px -4292px }
.pokesprite.lycanroc-midnight.shiny { background-position: -1330px -4292px }
.pokesprite.wishiwashi.shiny { background-position: -1400px -4292px }
.pokesprite.wishiwashi-solo.shiny { background-position: -1400px -4292px }
.pokesprite.wishiwashi-school.shiny { background-position: -1470px -4292px }
.pokesprite.mareanie.shiny { background-position: -1540px -4292px }
.pokesprite.toxapex.shiny { background-position: -1610px -4292px }
.pokesprite.mudbray.shiny { background-position: -1680px -4292px }
.pokesprite.mudsdale.shiny { background-position: -1750px -4292px }
.pokesprite.dewpider.shiny { background-position: -1820px -4292px }
.pokesprite.araquanid.shiny { background-position: -1890px -4292px }
.pokesprite.araquanid-totem.shiny { background-position: -1890px -4292px }
.pokesprite.fomantis.shiny { background-position: -1960px -4292px }
.pokesprite.lurantis.shiny { background-position: -2030px -4292px }
.pokesprite.lurantis-totem.shiny { background-position: -2030px -4292px }
.pokesprite.morelull.shiny { background-position: -2100px -4292px }
.pokesprite.shiinotic.shiny { background-position: -0px -4350px }
.pokesprite.salandit.shiny { background-position: -70px -4350px }
.pokesprite.salazzle.shiny { background-position: -140px -4350px }
.pokesprite.salazzle-totem.shiny { background-position: -140px -4350px }
.pokesprite.stufful.shiny { background-position: -210px -4350px }
.pokesprite.bewear.shiny { background-position: -280px -4350px }
.pokesprite.bounsweet.shiny { background-position: -350px -4350px }
.pokesprite.steenee.shiny { background-position: -420px -4350px }
.pokesprite.tsareena.shiny { background-position: -490px -4350px }
.pokesprite.comfey.shiny { background-position: -560px -4350px }
.pokesprite.oranguru.shiny { background-position: -630px -4350px }
.pokesprite.passimian.shiny { background-position: -700px -4350px }
.pokesprite.wimpod.shiny { background-position: -770px -4350px }
.pokesprite.golisopod.shiny { background-position: -840px -4350px }
.pokesprite.sandygast.shiny { background-position: -910px -4350px }
.pokesprite.palossand.shiny { background-position: -980px -4350px }
.pokesprite.pyukumuku.shiny { background-position: -1050px -4350px }
.pokesprite.type-null.shiny { background-position: -1120px -4350px }
.pokesprite.silvally.shiny { background-position: -1190px -4350px }
.pokesprite.silvally-normal.shiny { background-position: -1190px -4350px }
.pokesprite.silvally-bug.shiny { background-position: -1260px -4350px }
.pokesprite.silvally-dark.shiny { background-position: -1330px -4350px }
.pokesprite.silvally-dragon.shiny { background-position: -1400px -4350px }
.pokesprite.silvally-electric.shiny { background-position: -1470px -4350px }
.pokesprite.silvally-fairy.shiny { background-position: -1540px -4350px }
.pokesprite.silvally-fighting.shiny { background-position: -1610px -4350px }
.pokesprite.silvally-fire.shiny { background-position: -1680px -4350px }
.pokesprite.silvally-flying.shiny { background-position: -1750px -4350px }
.pokesprite.silvally-ghost.shiny { background-position: -1820px -4350px }
.pokesprite.silvally-grass.shiny { background-position: -1890px -4350px }
.pokesprite.silvally-ground.shiny { background-position: -1960px -4350px }
.pokesprite.silvally-ice.shiny { background-position: -2030px -4350px }
.pokesprite.silvally-poison.shiny { background-position: -2100px -4350px }
.pokesprite.silvally-psychic.shiny { background-position: -0px -4408px }
.pokesprite.silvally-rock.shiny { background-position: -70px -4408px }
.pokesprite.silvally-steel.shiny { background-position: -140px -4408px }
.pokesprite.silvally-water.shiny { background-position: -210px -4408px }
.pokesprite.minior.shiny { background-position: -280px -4408px }
.pokesprite.minior-blue-meteor.shiny { background-position: -280px -4408px }
.pokesprite.minior-green-meteor.shiny { background-position: -280px -4408px }
.pokesprite.minior-indigo-meteor.shiny { background-position: -280px -4408px }
.pokesprite.minior-orange-meteor.shiny { background-position: -280px -4408px }
.pokesprite.minior-red-meteor.shiny { background-position: -280px -4408px }
.pokesprite.minior-violet-meteor.shiny { background-position: -280px -4408px }
.pokesprite.minior-yellow-meteor.shiny { background-position: -280px -4408px }
.pokesprite.minior-blue.shiny { background-position: -350px -4408px }
.pokesprite.minior-blue-gen7.shiny { background-position: -420px -4408px }
.pokesprite.minior-green-gen7.shiny { background-position: -420px -4408px }
.pokesprite.minior-indigo-gen7.shiny { background-position: -420px -4408px }
.pokesprite.minior-orange-gen7.shiny { background-position: -420px -4408px }
.pokesprite.minior-red-gen7.shiny { background-position: -420px -4408px }
.pokesprite.minior-violet-gen7.shiny { background-position: -420px -4408px }
.pokesprite.minior-yellow-gen7.shiny { background-position: -420px -4408px }
.pokesprite.minior-green.shiny { background-position: -490px -4408px }
.pokesprite.minior-indigo.shiny { background-position: -560px -4408px }
.pokesprite.minior-orange.shiny { background-position: -630px -4408px }
.pokesprite.minior-red.shiny { background-position: -700px -4408px }
.pokesprite.minior-violet.shiny { background-position: -770px -4408px }
.pokesprite.minior-yellow.shiny { background-position: -840px -4408px }
.pokesprite.komala.shiny { background-position: -910px -4408px }
.pokesprite.turtonator.shiny { background-position: -980px -4408px }
.pokesprite.togedemaru.shiny { background-position: -1050px -4408px }
.pokesprite.togedemaru-totem.shiny { background-position: -1050px -4408px }
.pokesprite.mimikyu.shiny { background-position: -1120px -4408px }
.pokesprite.mimikyu-busted.shiny { background-position: -1120px -4408px }
.pokesprite.mimikyu-disguised.shiny { background-position: -1120px -4408px }
.pokesprite.mimikyu-totem.shiny { background-position: -1120px -4408px }
.pokesprite.mimikyu-totem-busted.shiny { background-position: -1120px -4408px }
.pokesprite.mimikyu-totem-disguised.shiny { background-position: -1120px -4408px }
.pokesprite.bruxish.shiny { background-position: -1190px -4408px }
.pokesprite.drampa.shiny { background-position: -1260px -4408px }
.pokesprite.dhelmise.shiny { background-position: -1330px -4408px }
.pokesprite.jangmo-o.shiny { background-position: -1400px -4408px }
.pokesprite.hakamo-o.shiny { background-position: -1470px -4408px }
.pokesprite.kommo-o.shiny { background-position: -1540px -4408px }
.pokesprite.kommo-o-totem.shiny { background-position: -1540px -4408px }
.pokesprite.tapu-koko.shiny { background-position: -1610px -4408px }
.pokesprite.tapu-lele.shiny { background-position: -1680px -4408px }
.pokesprite.tapu-bulu.shiny { background-position: -1750px -4408px }
.pokesprite.tapu-fini.shiny { background-position: -1820px -4408px }
.pokesprite.cosmog.shiny { background-position: -1890px -4408px }
.pokesprite.cosmoem.shiny { background-position: -1960px -4408px }
.pokesprite.solgaleo.shiny { background-position: -2030px -4408px }
.pokesprite.lunala.shiny { background-position: -2100px -4408px }
.pokesprite.nihilego.shiny { background-position: -0px -4466px }
.pokesprite.buzzwole.shiny { background-position: -70px -4466px }
.pokesprite.pheromosa.shiny { background-position: -140px -4466px }
.pokesprite.xurkitree.shiny { background-position: -210px -4466px }
.pokesprite.celesteela.shiny { background-position: -280px -4466px }
.pokesprite.kartana.shiny { background-position: -350px -4466px }
.pokesprite.guzzlord.shiny { background-position: -420px -4466px }
.pokesprite.necrozma.shiny { background-position: -490px -4466px }
.pokesprite.necrozma-dawn.shiny { background-position: -560px -4466px }
.pokesprite.necrozma-dusk.shiny { background-position: -630px -4466px }
.pokesprite.necrozma-ultra.shiny { background-position: -700px -4466px }
.pokesprite.magearna.shiny { background-position: -770px -4466px }
.pokesprite.magearna-original.shiny { background-position: -840px -4466px }
.pokesprite.marshadow.shiny { background-position: -910px -4466px }
.pokesprite.marshadow-gen7.shiny { background-position: -980px -4466px }
.pokesprite.poipole.shiny { background-position: -1050px -4466px }
.pokesprite.naganadel.shiny { background-position: -1120px -4466px }
.pokesprite.stakataka.shiny { background-position: -1190px -4466px }
.pokesprite.blacephalon.shiny { background-position: -1260px -4466px }
.pokesprite.zeraora.shiny { background-position: -1330px -4466px }
.pokesprite.meltan.shiny { background-position: -1400px -4466px }
.pokesprite.melmetal.shiny { background-position: -1470px -4466px }
.pokesprite.melmetal-gmax.shiny { background-position: -1540px -4466px }
.pokesprite.grookey.shiny { background-position: -1610px -4466px }
.pokesprite.thwackey.shiny { background-position: -1680px -4466px }
.pokesprite.rillaboom.shiny { background-position: -1750px -4466px }
.pokesprite.rillaboom-gmax.shiny { background-position: -1820px -4466px }
.pokesprite.scorbunny.shiny { background-position: -1890px -4466px }
.pokesprite.raboot.shiny { background-position: -1960px -4466px }
.pokesprite.cinderace.shiny { background-position: -2030px -4466px }
.pokesprite.cinderace-gmax.shiny { background-position: -2100px -4466px }
.pokesprite.sobble.shiny { background-position: -0px -4524px }
.pokesprite.drizzile.shiny { background-position: -70px -4524px }
.pokesprite.inteleon.shiny { background-position: -140px -4524px }
.pokesprite.inteleon-gmax.shiny { background-position: -210px -4524px }
.pokesprite.skwovet.shiny { background-position: -280px -4524px }
.pokesprite.greedent.shiny { background-position: -350px -4524px }
.pokesprite.rookidee.shiny { background-position: -420px -4524px }
.pokesprite.corvisquire.shiny { background-position: -490px -4524px }
.pokesprite.corviknight.shiny { background-position: -560px -4524px }
.pokesprite.corviknight-gmax.shiny { background-position: -630px -4524px }
.pokesprite.blipbug.shiny { background-position: -700px -4524px }
.pokesprite.dottler.shiny { background-position: -770px -4524px }
.pokesprite.orbeetle.shiny { background-position: -840px -4524px }
.pokesprite.orbeetle-gmax.shiny { background-position: -910px -4524px }
.pokesprite.nickit.shiny { background-position: -980px -4524px }
.pokesprite.thievul.shiny { background-position: -1050px -4524px }
.pokesprite.gossifleur.shiny { background-position: -1120px -4524px }
.pokesprite.eldegoss.shiny { background-position: -1190px -4524px }
.pokesprite.wooloo.shiny { background-position: -1260px -4524px }
.pokesprite.dubwool.shiny { background-position: -1330px -4524px }
.pokesprite.chewtle.shiny { background-position: -1400px -4524px }
.pokesprite.drednaw.shiny { background-position: -1470px -4524px }
.pokesprite.drednaw-gmax.shiny { background-position: -1540px -4524px }
.pokesprite.yamper.shiny { background-position: -1610px -4524px }
.pokesprite.boltund.shiny { background-position: -1680px -4524px }
.pokesprite.rolycoly.shiny { background-position: -1750px -4524px }
.pokesprite.carkol.shiny { background-position: -1820px -4524px }
.pokesprite.coalossal.shiny { background-position: -1890px -4524px }
.pokesprite.coalossal-gmax.shiny { background-position: -1960px -4524px }
.pokesprite.applin.shiny { background-position: -2030px -4524px }
.pokesprite.flapple.shiny { background-position: -2100px -4524px }
.pokesprite.flapple-gmax.shiny { background-position: -0px -4582px }
.pokesprite.appletun.shiny { background-position: -70px -4582px }
.pokesprite.silicobra.shiny { background-position: -140px -4582px }
.pokesprite.sandaconda.shiny { background-position: -210px -4582px }
.pokesprite.sandaconda-gmax.shiny { background-position: -280px -4582px }
.pokesprite.cramorant.shiny { background-position: -350px -4582px }
.pokesprite.cramorant-gorging.shiny { background-position: -420px -4582px }
.pokesprite.cramorant-gulping.shiny { background-position: -490px -4582px }
.pokesprite.arrokuda.shiny { background-position: -560px -4582px }
.pokesprite.barraskewda.shiny { background-position: -630px -4582px }
.pokesprite.toxel.shiny { background-position: -700px -4582px }
.pokesprite.toxtricity.shiny { background-position: -770px -4582px }
.pokesprite.toxtricity-amped.shiny { background-position: -770px -4582px }
.pokesprite.toxtricity-gmax.shiny { background-position: -840px -4582px }
.pokesprite.toxtricity-low-key-gmax.shiny { background-position: -840px -4582px }
.pokesprite.toxtricity-low-key.shiny { background-position: -910px -4582px }
.pokesprite.sizzlipede.shiny { background-position: -980px -4582px }
.pokesprite.centiskorch.shiny { background-position: -1050px -4582px }
.pokesprite.centiskorch-gmax.shiny { background-position: -1120px -4582px }
.pokesprite.clobbopus.shiny { background-position: -1190px -4582px }
.pokesprite.grapploct.shiny { background-position: -1260px -4582px }
.pokesprite.sinistea.shiny { background-position: -1330px -4582px }
.pokesprite.polteageist.shiny { background-position: -1400px -4582px }
.pokesprite.hatenna.shiny { background-position: -1470px -4582px }
.pokesprite.hattrem.shiny { background-position: -1540px -4582px }
.pokesprite.hatterene.shiny { background-position: -1610px -4582px }
.pokesprite.hatterene-gmax.shiny { background-position: -1680px -4582px }
.pokesprite.impidimp.shiny { background-position: -1750px -4582px }
.pokesprite.morgrem.shiny { background-position: -1820px -4582px }
.pokesprite.grimmsnarl.shiny { background-position: -1890px -4582px }
.pokesprite.grimmsnarl-gmax.shiny { background-position: -1960px -4582px }
.pokesprite.obstagoon.shiny { background-position: -2030px -4582px }
.pokesprite.perrserker.shiny { background-position: -2100px -4582px }
.pokesprite.cursola.shiny { background-position: -0px -4640px }
.pokesprite.sirfetchd.shiny { background-position: -70px -4640px }
.pokesprite.mr-rime.shiny { background-position: -140px -4640px }
.pokesprite.runerigus.shiny { background-position: -210px -4640px }
.pokesprite.milcery.shiny { background-position: -280px -4640px }
.pokesprite.alcremie.shiny { background-position: -350px -4640px }
.pokesprite.alcremie-caramel-swirl-berry.shiny { background-position: -420px -4640px }
.pokesprite.alcremie-caramel-swirl-clover.shiny { background-position: -490px -4640px }
.pokesprite.alcremie-caramel-swirl-flower.shiny { background-position: -560px -4640px }
.pokesprite.alcremie-caramel-swirl-love.shiny { background-position: -630px -4640px }
.pokesprite.alcremie-caramel-swirl-plain.shiny { background-position: -700px -4640px }
.pokesprite.alcremie-caramel-swirl-ribbon.shiny { background-position: -770px -4640px }
.pokesprite.alcremie-caramel-swirl-star.shiny { background-position: -840px -4640px }
.pokesprite.alcremie-caramel-swirl-strawberry.shiny { background-position: -910px -4640px }
.pokesprite.alcremie-gmax.shiny { background-position: -980px -4640px }
.pokesprite.alcremie-lemon-cream-berry.shiny { background-position: -1050px -4640px }
.pokesprite.alcremie-lemon-cream-clover.shiny { background-position: -1120px -4640px }
.pokesprite.alcremie-lemon-cream-flower.shiny { background-position: -1190px -4640px }
.pokesprite.alcremie-lemon-cream-love.shiny { background-position: -1260px -4640px }
.pokesprite.alcremie-lemon-cream-plain.shiny { background-position: -1330px -4640px }
.pokesprite.alcremie-lemon-cream-ribbon.shiny { background-position: -1400px -4640px }
.pokesprite.alcremie-lemon-cream-star.shiny { background-position: -1470px -4640px }
.pokesprite.alcremie-lemon-cream-strawberry.shiny { background-position: -1540px -4640px }
.pokesprite.alcremie-matcha-cream-berry.shiny { background-position: -1610px -4640px }
.pokesprite.alcremie-matcha-cream-clover.shiny { background-position: -1680px -4640px }
.pokesprite.alcremie-matcha-cream-flower.shiny { background-position: -1750px -4640px }
.pokesprite.alcremie-matcha-cream-love.shiny { background-position: -1820px -4640px }
.pokesprite.alcremie-matcha-cream-plain.shiny { background-position: -1890px -4640px }
.pokesprite.alcremie-matcha-cream-ribbon.shiny { background-position: -1960px -4640px }
.pokesprite.alcremie-matcha-cream-star.shiny { background-position: -2030px -4640px }
.pokesprite.alcremie-matcha-cream-strawberry.shiny { background-position: -2100px -4640px }
.pokesprite.alcremie-mint-cream-berry.shiny { background-position: -0px -4698px }
.pokesprite.alcremie-mint-cream-clover.shiny { background-position: -70px -4698px }
.pokesprite.alcremie-mint-cream-flower.shiny { background-position: -140px -4698px }
.pokesprite.alcremie-mint-cream-love.shiny { background-position: -210px -4698px }
.pokesprite.alcremie-mint-cream-plain.shiny { background-position: -280px -4698px }
.pokesprite.alcremie-mint-cream-ribbon.shiny { background-position: -350px -4698px }
.pokesprite.alcremie-mint-cream-star.shiny { background-position: -420px -4698px }
.pokesprite.alcremie-mint-cream-strawberry.shiny { background-position: -490px -4698px }
.pokesprite.alcremie-rainbow-swirl-berry.shiny { background-position: -560px -4698px }
.pokesprite.alcremie-rainbow-swirl-clover.shiny { background-position: -630px -4698px }
.pokesprite.alcremie-rainbow-swirl-flower.shiny { background-position: -700px -4698px }
.pokesprite.alcremie-rainbow-swirl-love.shiny { background-position: -770px -4698px }
.pokesprite.alcremie-rainbow-swirl-plain.shiny { background-position: -840px -4698px }
.pokesprite.alcremie-rainbow-swirl-ribbon.shiny { background-position: -910px -4698px }
.pokesprite.alcremie-rainbow-swirl-star.shiny { background-position: -980px -4698px }
.pokesprite.alcremie-rainbow-swirl-strawberry.shiny { background-position: -1050px -4698px }
.pokesprite.alcremie-ruby-cream-berry.shiny { background-position: -1120px -4698px }
.pokesprite.alcremie-ruby-cream-clover.shiny { background-position: -1190px -4698px }
.pokesprite.alcremie-ruby-cream-flower.shiny { background-position: -1260px -4698px }
.pokesprite.alcremie-ruby-cream-love.shiny { background-position: -1330px -4698px }
.pokesprite.alcremie-ruby-cream-plain.shiny { background-position: -1400px -4698px }
.pokesprite.alcremie-ruby-cream-ribbon.shiny { background-position: -1470px -4698px }
.pokesprite.alcremie-ruby-cream-star.shiny { background-position: -1540px -4698px }
.pokesprite.alcremie-ruby-cream-strawberry.shiny { background-position: -1610px -4698px }
.pokesprite.alcremie-ruby-swirl-berry.shiny { background-position: -1680px -4698px }
.pokesprite.alcremie-ruby-swirl-clover.shiny { background-position: -1750px -4698px }
.pokesprite.alcremie-ruby-swirl-flower.shiny { background-position: -1820px -4698px }
.pokesprite.alcremie-ruby-swirl-love.shiny { background-position: -1890px -4698px }
.pokesprite.alcremie-ruby-swirl-plain.shiny { background-position: -1960px -4698px }
.pokesprite.alcremie-ruby-swirl-ribbon.shiny { background-position: -2030px -4698px }
.pokesprite.alcremie-ruby-swirl-star.shiny { background-position: -2100px -4698px }
.pokesprite.alcremie-ruby-swirl-strawberry.shiny { background-position: -0px -4756px }
.pokesprite.alcremie-salted-cream-berry.shiny { background-position: -70px -4756px }
.pokesprite.alcremie-salted-cream-clover.shiny { background-position: -140px -4756px }
.pokesprite.alcremie-salted-cream-flower.shiny { background-position: -210px -4756px }
.pokesprite.alcremie-salted-cream-love.shiny { background-position: -280px -4756px }
.pokesprite.alcremie-salted-cream-plain.shiny { background-position: -350px -4756px }
.pokesprite.alcremie-salted-cream-ribbon.shiny { background-position: -420px -4756px }
.pokesprite.alcremie-salted-cream-star.shiny { background-position: -490px -4756px }
.pokesprite.alcremie-salted-cream-strawberry.shiny { background-position: -560px -4756px }
.pokesprite.alcremie-vanilla-cream-berry.shiny { background-position: -630px -4756px }
.pokesprite.alcremie-vanilla-cream-clover.shiny { background-position: -700px -4756px }
.pokesprite.alcremie-vanilla-cream-flower.shiny { background-position: -770px -4756px }
.pokesprite.alcremie-vanilla-cream-love.shiny { background-position: -840px -4756px }
.pokesprite.alcremie-vanilla-cream-plain.shiny { background-position: -910px -4756px }
.pokesprite.alcremie-vanilla-cream-ribbon.shiny { background-position: -980px -4756px }
.pokesprite.alcremie-vanilla-cream-star.shiny { background-position: -1050px -4756px }
.pokesprite.alcremie-vanilla-cream-strawberry.shiny { background-position: -1120px -4756px }
.pokesprite.falinks.shiny { background-position: -1190px -4756px }
.pokesprite.pincurchin.shiny { background-position: -1260px -4756px }
.pokesprite.snom.shiny { background-position: -1330px -4756px }
.pokesprite.frosmoth.shiny { background-position: -1400px -4756px }
.pokesprite.stonjourner.shiny { background-position: -1470px -4756px }
.pokesprite.eiscue.shiny { background-position: -1540px -4756px }
.pokesprite.eiscue-ice.shiny { background-position: -1540px -4756px }
.pokesprite.eiscue-noice.shiny { background-position: -1610px -4756px }
.pokesprite.indeedee.shiny { background-position: -1680px -4756px }
.pokesprite.indeedee.shiny.female { background-position: -1750px -4756px }
.pokesprite.morpeko.shiny { background-position: -1820px -4756px }
.pokesprite.morpeko-full-belly.shiny { background-position: -1820px -4756px }
.pokesprite.morpeko-hangry.shiny { background-position: -1890px -4756px }
.pokesprite.cufant.shiny { background-position: -1960px -4756px }
.pokesprite.copperajah.shiny { background-position: -2030px -4756px }
.pokesprite.copperajah-gmax.shiny { background-position: -2100px -4756px }
.pokesprite.dracozolt.shiny { background-position: -0px -4814px }
.pokesprite.arctozolt.shiny { background-position: -70px -4814px }
.pokesprite.dracovish.shiny { background-position: -140px -4814px }
.pokesprite.arctovish.shiny { background-position: -210px -4814px }
.pokesprite.duraludon.shiny { background-position: -280px -4814px }
.pokesprite.duraludon-gmax.shiny { background-position: -350px -4814px }
.pokesprite.dreepy.shiny { background-position: -420px -4814px }
.pokesprite.drakloak.shiny { background-position: -490px -4814px }
.pokesprite.dragapult.shiny { background-position: -560px -4814px }
.pokesprite.zacian.shiny { background-position: -630px -4814px }
.pokesprite.zacian-hero-of-many-battles.shiny { background-position: -630px -4814px }
.pokesprite.zacian-crowned.shiny { background-position: -700px -4814px }
.pokesprite.zamazenta.shiny { background-position: -770px -4814px }
.pokesprite.zamazenta-hero-of-many-battles.shiny { background-position: -770px -4814px }
.pokesprite.zamazenta-crowned.shiny { background-position: -840px -4814px }
.pokesprite.eternatus.shiny { background-position: -910px -4814px }
.pokesprite.eternatus-eternamax.shiny { background-position: -980px -4814px }
.pokesprite.kubfu.shiny { background-position: -1050px -4814px }
.pokesprite.urshifu.shiny { background-position: -1120px -4814px }
.pokesprite.urshifu-gmax.shiny { background-position: -1190px -4814px }
.pokesprite.urshifu-single-strike-gmax.shiny { background-position: -1190px -4814px }
.pokesprite.urshifu-rapid-strike-gmax.shiny { background-position: -1260px -4814px }
.pokesprite.zarude.shiny { background-position: -1330px -4814px }
.pokesprite.zarude-dada.shiny { background-position: -1400px -4814px }
.pokesprite.manaphy-egg { background-position: -1470px -4814px }
.pokesprite.egg { background-position: -1540px -4814px }
.pokesprite.mega-evolution-sigil { background-position: -1610px -4814px }
.pokesprite.unknown-gen5 { background-position: -1680px -4814px }
.pokesprite.unknown { background-position: -1750px -4814px }